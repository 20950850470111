.slick-prev:before, .slick-next:before{
  content: '' !important;
}
.sidenav-icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  display: inline-block;
  margin-right: 8px;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0
}

table.dataTable thead th,table.dataTable tfoot th {
  font-weight: bold
}

table.dataTable thead th,table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111
}

table.dataTable thead th:active,table.dataTable thead td:active {
  outline: none
}

table.dataTable tfoot th,table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111
}

table.dataTable thead .sorting,table.dataTable thead .sorting_asc,table.dataTable thead .sorting_desc,table.dataTable thead .sorting_asc_disabled,table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
 
  background-repeat: no-repeat;
  background-position: center right
}

table.dataTable thead .sorting {
  background-image: url("https://www.jeetbuzz.com/images/sort_both.png")
}

table.dataTable thead .sorting_asc {
  background-image: url("https://www.jeetbuzz.com/images/sort_asc.png")
}

table.dataTable thead .sorting_desc {
  background-image: url("https://www.jeetbuzz.com/images/sort_desc.png")
}

table.dataTable thead .sorting_asc_disabled {
  background-image: url("https://www.jeetbuzz.com/images/sort_asc_disabled.png")
}

table.dataTable thead .sorting_desc_disabled {
  background-image: url("https://www.jeetbuzz.com/images/sort_desc_disabled.png")
}

table.dataTable tbody tr {
  background-color: #ffffff
}

table.dataTable tbody tr.selected {
  background-color: #B0BED9
}

table.dataTable tbody th,table.dataTable tbody td {
  padding: 8px 10px
}

table.dataTable.row-border tbody th,table.dataTable.row-border tbody td,table.dataTable.display tbody th,table.dataTable.display tbody td {
  border-top: 1px solid #ddd
}

table.dataTable.row-border tbody tr:first-child th,table.dataTable.row-border tbody tr:first-child td,table.dataTable.display tbody tr:first-child th,table.dataTable.display tbody tr:first-child td {
  border-top: none
}

table.dataTable.cell-border tbody th,table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd
}

table.dataTable.cell-border tbody tr th:first-child,table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd
}

table.dataTable.cell-border tbody tr:first-child th,table.dataTable.cell-border tbody tr:first-child td {
  border-top: none
}

table.dataTable.stripe tbody tr.odd,table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9
}

table.dataTable.stripe tbody tr.odd.selected,table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4
}

table.dataTable.hover tbody tr:hover,table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6
}

table.dataTable.hover tbody tr:hover.selected,table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1
}

table.dataTable.order-column tbody tr>.sorting_1,table.dataTable.order-column tbody tr>.sorting_2,table.dataTable.order-column tbody tr>.sorting_3,table.dataTable.display tbody tr>.sorting_1,table.dataTable.display tbody tr>.sorting_2,table.dataTable.display tbody tr>.sorting_3 {
  background-color: #fafafa
}

table.dataTable.order-column tbody tr.selected>.sorting_1,table.dataTable.order-column tbody tr.selected>.sorting_2,table.dataTable.order-column tbody tr.selected>.sorting_3,table.dataTable.display tbody tr.selected>.sorting_1,table.dataTable.display tbody tr.selected>.sorting_2,table.dataTable.display tbody tr.selected>.sorting_3 {
  background-color: #acbad5
}

table.dataTable.display tbody tr.odd>.sorting_1,table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
  background-color: #f1f1f1
}

table.dataTable.display tbody tr.odd>.sorting_2,table.dataTable.order-column.stripe tbody tr.odd>.sorting_2 {
  background-color: #f3f3f3
}

table.dataTable.display tbody tr.odd>.sorting_3,table.dataTable.order-column.stripe tbody tr.odd>.sorting_3 {
  background-color: whitesmoke
}

table.dataTable.display tbody tr.odd.selected>.sorting_1,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
  background-color: #a6b4cd
}

table.dataTable.display tbody tr.odd.selected>.sorting_2,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_2 {
  background-color: #a8b5cf
}

table.dataTable.display tbody tr.odd.selected>.sorting_3,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_3 {
  background-color: #a9b7d1
}

table.dataTable.display tbody tr.even>.sorting_1,table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
  background-color: #fafafa
}

table.dataTable.display tbody tr.even>.sorting_2,table.dataTable.order-column.stripe tbody tr.even>.sorting_2 {
  background-color: #fcfcfc
}

table.dataTable.display tbody tr.even>.sorting_3,table.dataTable.order-column.stripe tbody tr.even>.sorting_3 {
  background-color: #fefefe
}

table.dataTable.display tbody tr.even.selected>.sorting_1,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
  background-color: #acbad5
}

table.dataTable.display tbody tr.even.selected>.sorting_2,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_2 {
  background-color: #aebcd6
}

table.dataTable.display tbody tr.even.selected>.sorting_3,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_3 {
  background-color: #afbdd8
}

table.dataTable.display tbody tr:hover>.sorting_1,table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
  background-color: #eaeaea
}

table.dataTable.display tbody tr:hover>.sorting_2,table.dataTable.order-column.hover tbody tr:hover>.sorting_2 {
  background-color: #ececec
}

table.dataTable.display tbody tr:hover>.sorting_3,table.dataTable.order-column.hover tbody tr:hover>.sorting_3 {
  background-color: #efefef
}

table.dataTable.display tbody tr:hover.selected>.sorting_1,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
  background-color: #a2aec7
}

table.dataTable.display tbody tr:hover.selected>.sorting_2,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_2 {
  background-color: #a3b0c9
}

table.dataTable.display tbody tr:hover.selected>.sorting_3,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_3 {
  background-color: #a5b2cb
}

table.dataTable.no-footer {
  border-bottom: 1px solid #111
}

table.dataTable.nowrap th,table.dataTable.nowrap td {
  white-space: nowrap
}

table.dataTable.compact thead th,table.dataTable.compact thead td {
  padding: 4px 17px
}

table.dataTable.compact tfoot th,table.dataTable.compact tfoot td {
  padding: 4px
}

table.dataTable.compact tbody th,table.dataTable.compact tbody td {
  padding: 4px
}

table.dataTable th.dt-left,table.dataTable td.dt-left {
  text-align: left
}

table.dataTable th.dt-center,table.dataTable td.dt-center,table.dataTable td.dataTables_empty {
  text-align: center
}

table.dataTable th.dt-right,table.dataTable td.dt-right {
  text-align: right
}

table.dataTable th.dt-justify,table.dataTable td.dt-justify {
  text-align: justify
}

table.dataTable th.dt-nowrap,table.dataTable td.dt-nowrap {
  white-space: nowrap
}

table.dataTable thead th.dt-head-left,table.dataTable thead td.dt-head-left,table.dataTable tfoot th.dt-head-left,table.dataTable tfoot td.dt-head-left {
  text-align: left
}

table.dataTable thead th.dt-head-center,table.dataTable thead td.dt-head-center,table.dataTable tfoot th.dt-head-center,table.dataTable tfoot td.dt-head-center {
  text-align: center
}

table.dataTable thead th.dt-head-right,table.dataTable thead td.dt-head-right,table.dataTable tfoot th.dt-head-right,table.dataTable tfoot td.dt-head-right {
  text-align: right
}

table.dataTable thead th.dt-head-justify,table.dataTable thead td.dt-head-justify,table.dataTable tfoot th.dt-head-justify,table.dataTable tfoot td.dt-head-justify {
  text-align: justify
}

table.dataTable thead th.dt-head-nowrap,table.dataTable thead td.dt-head-nowrap,table.dataTable tfoot th.dt-head-nowrap,table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap
}

table.dataTable tbody th.dt-body-left,table.dataTable tbody td.dt-body-left {
  text-align: left
}

table.dataTable tbody th.dt-body-center,table.dataTable tbody td.dt-body-center {
  text-align: center
}

table.dataTable tbody th.dt-body-right,table.dataTable tbody td.dt-body-right {
  text-align: right
}

table.dataTable tbody th.dt-body-justify,table.dataTable tbody td.dt-body-justify {
  text-align: justify
}

table.dataTable tbody th.dt-body-nowrap,table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap
}

table.dataTable,table.dataTable th,table.dataTable td {
  box-sizing: content-box
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  zoom:1}

.dataTables_wrapper .dataTables_length {
  float: left
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  margin-left: 3px
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%)
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%)
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%)
}

.dataTables_wrapper .dataTables_length,.dataTables_wrapper .dataTables_filter,.dataTables_wrapper .dataTables_info,.dataTables_wrapper .dataTables_processing,.dataTables_wrapper .dataTables_paginate {
  color: #333
}

.dataTables_wrapper .dataTables_scroll {
  clear: both
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  margin-top: -1px;
  -webkit-overflow-scrolling: touch
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
  vertical-align: middle
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td>div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,.dataTables_wrapper.no-footer div.dataTables_scrollBody>table {
  border-bottom: none
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,.dataTables_wrapper .dataTables_paginate {
      float:none;
      text-align: center
  }

  .dataTables_wrapper .dataTables_paginate {
      margin-top: 0.5em
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,.dataTables_wrapper .dataTables_filter {
      float:none;
      text-align: center
  }

  .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em
  }
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.bg-tran{
  background: transparent !important;
}

.tag-rebate-money {
  font-family: Bebas Neue, Helvetica, Tahoma, sans-serif;
}
header {
  z-index: 6000;
}
.menu {
  z-index: 9000;
}
.pop-bg {
  z-index: 8000;
}
.pop-wrap,
.member-menu,
.searchpage {
  z-index: 9000;
}
.menu-mask,
.modal {
  z-index: 8000;
}
.modal-backdrop {
  z-index: 7000;
}
.launch-game-content {
  z-index: 8500;
}
.tips-display {
  z-index: 8501;
}
.loading-mask {
  z-index: 9003;
}
.loader-box {
  z-index: 9004 !important;
}
.rotating-pop {
  z-index: 9002;
}
#chat-widget-container {
  z-index: 9001 !important;
}

.tag-rebate-money {
  position: absolute;
  z-index: 3;
  top: 1.3333333333vw;
  right: -1.0666666667vw;
  padding: 0 0.8vw;
  animation: _ngcontent-serverApp-c700970540_tagRebateBoney 1s 0.3s forwards;
  border-radius: 0 0.8vw 0.8vw 0;
  opacity: 0;
  background: #d15454;
  box-shadow: 0 0 2px #0000004d;
  color: #fff;
  line-height: 0;
  pointer-events: none;
}
.tag-rebate-money:before,
.tag-rebate-money:after {
  content: "";
  position: absolute;
  left: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  background: transparent;
}
.tag-rebate-money:before {
  top: 0;
  border-width: 0 6px 11px 0;
  border-color: transparent #d15454 transparent transparent;
}
.tag-rebate-money:after {
  bottom: 0;
  border-width: 0 0 11px 6px;
  border-color: transparent transparent #d15454;
}
.tag-rebate-money p,
.tag-rebate-money span {
  display: inline-block;
  vertical-align: text-bottom;
}
.tag-rebate-money span {
  width: auto !important;
  font-size: 3.4666666667vw;
  letter-spacing: 0;
  line-height: 1.5;
}
.tag-rebate-money p {
  font-size: 4.2666666667vw;
  letter-spacing: -1px;
  height: 5.8666666667vw;
}
@keyframes _ngcontent-serverApp-c700970540_tagRebateBoney {
  0% {
      right: -4.2666666667vw;
      opacity: 0;
  }
  60% {
      opacity: 1;
  }
  to {
      right: -1.0666666667vw;
      opacity: 1;
  }
}
html.is-desktop .tag-rebate-money,
html.is-desktop  .tag-rebate-money {
  top: 5px;
  right: -4px;
  border-radius: 0 3px 3px 0;
  padding: 0 3px;
}
html.is-desktop .tag-rebate-money p,
html.is-desktop  .tag-rebate-money p {
  font-size: 16px;
  letter-spacing: -1px;
  line-height: 1;
  height: 20px;
}
.three-dots-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

.three-dots-loading span {
  width: 10px;
  height: 10px;
  background-color: yellow; /* You can change this color */
  border-radius: 50%;
  animation: dots-animation 1.5s infinite;
}

.three-dots-loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.three-dots-loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots-animation {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

@keyframes _ngcontent-serverApp-c700970540_tagRebateBoney {
  0% {
      right: -16px;
      opacity: 0;
  }
  60% {
      opacity: 1;
  }
  to {
      right: -4px;
      opacity: 1;
  }
}
.text-white{
  color: #fff;
}

.header {
  z-index: 6000;
}
.menu {
  z-index: 9000;
}
.pop-bg {
  z-index: 8000;
}
.pop-wrap,
.member-menu,
.searchpage {
  z-index: 9000;
}
.menu-mask,
.modal {
  z-index: 8000;
}
.modal-backdrop {
  z-index: 7000;
}
.launch-game-content {
  z-index: 8500;
}
.tips-display {
  z-index: 8501;
}
.loading-mask {
  z-index: 9003;
}
.loader-box {
  z-index: 9004 !important;
}
.rotating-pop {
  z-index: 9002;
}
#chat-widget-container {
  z-index: 9001 !important;
}
[_nghost-serverApp-c1309121045] {
  display: block;
}
.referral-program {
  font-family: Helvetica Neue, sans-serif;
  position: relative;
}
.referral-program__bg {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.referral-program__bg img {
  width: 100%;
}
.referral-program__body {
  padding: 0 2.6666666667vw;
}
.referral-program__banner {
  position: relative;
  z-index: 2;
}
.referral-program__panel + .referral-program__panel {
  margin-top: 3.2vw;
}
.referral-program-downline-board,
.referral-program-panel {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: var(--referral-program-panel-shadow, 0px 2px 4px rgba(0, 0, 0, 0.5));
  border: 1px solid var(--referral-program-panel-border, #666);
  border-radius: 1.3333333333vw;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.referral-program-panel__tag,
.referral-program-downline-board__tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: var(--referral-program-tag-height, 27px);
  padding: 0 8vw 0 5.3333333333vw;
  font-size: 3.2vw;
  text-align: center;
  background: var(--referral-program-tag-bg, #ffde1a);
  color: var(--referral-program-tag-text, #000);
  box-shadow: inset 0 0.2666666667vw 0.2666666667vw 0 var(--referral-program-tag-shadow, rgba(255, 255, 255, 0.5));
  clip-path: path("M0,0 L800,0 L800,26.8193006 L19.9185168,26.8193006 C17.0429846,26.8193006 14.3886686,25.2760385 12.96609,22.7770476 L0,0 L0,0 Z");
}
.referral-program-panel__tag .tag-icon,
.referral-program-downline-board__tag .tag-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--referral-program-tag-height, 27px);
  height: var(--referral-program-tag-height, 27px);
  background: var(--referral-program-tag, #000);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 50%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: 50%;
}
.referral-program-panel {
  background-color: var(--referral-program-panel-bg, #333);
  padding: 6.4vw 2.6666666667vw 2.6666666667vw;
}
.referral-program-banner {
  padding-bottom: 69.8666666667%;
  overflow: hidden;
}
.referral-program-banner__logo {
  height: 8vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 9.6vw;
  margin: 0 auto;
}
.referral-program-banner__logo img {
  height: 100%;
}
.referral-program-banner__title {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 21.3333333333vw;
}
.referral-program-banner__title-fragment {
  display: block;
  white-space: nowrap;
  text-align: center;
  text-shadow: 0vw 0.5333333333vw 1.0666666667vw #000;
  font-size: 7.7333333333vw;
  line-height: 1;
  font-weight: 900;
}
.referral-program-banner__title-fragment--highlight {
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-banner__img {
  position: absolute;
  padding-bottom: 116.3636363636%;
  width: 100%;
  top: 0;
}
.referral-program-banner__img img {
  position: absolute;
  top: -6.6666666667vw;
  width: 100%;
}
.referral-program-flowch {
  padding: 6.4vw 5.3333333333vw 8vw;
}
.referral-program-flowch__title {
  font-weight: 700;
  font-size: 4.8vw;
  text-align: center;
}
.referral-program-flowch__title + .referral-program-flowch__steps {
  margin-top: 7.2vw;
}
.referral-program-flowch__steps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.referral-program-flowch__steps > * {
  flex: none;
}
.referral-program-flowch__arrow {
  width: 5.3333333333vw;
  margin-top: 5.0666666667vw;
  opacity: 0.5;
}
.referral-program-flowch__step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referral-program-flowch__arrow > .referral-program-flowch__img {
  width: 100%;
}
.referral-program-flowch__step > .referral-program-flowch__img {
  width: 16vw;
}
.referral-program-flowch__img + .referral-program-flowch__text {
  margin-top: 4.8vw;
}
.referral-program-flowch__text {
  color: var(--referral-program-text-highlight, #ffde1a);
  font-weight: 700;
  text-align: center;
  font-size: 3.2vw;
  line-height: 1.5;
}
.referral-code-panel-qrcode __inner {
  width: 100%;
}
.referral-code-panel-qrcode img {
  width: 100%;
}
.referral-code-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.referral-code-panel__title {
  font-size: 3.7333333333vw;
  text-align: center;
  color: var(--referral-program-panel-code-title, #b5b5b5);
}
.referral-code-panel__qrcode {
  display: block;
  padding: 4vw 0;
  filter: invert(1);
  mix-blend-mode: lighten;
  width: 38.4vw;
  padding: 5.3333333333vw;
}
.referral-code-panel__btn-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.referral-code-panel__btn {
  flex: 1;
  border: none;
  background-color: var(--referral-program-text-highlight, #ffde1a);
  font-size: 3.7333333333vw;
  text-align: center;
  color: var(--referral-program-panel-btn-text, #000);
  padding: 3.7333333333vw 3.7333333333vw 4.5333333333vw;
  width: 100%;
  border-radius: 1.3333333333vw;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.referral-code-panel__btn-img {
  width: 5.6vw;
  height: 5.6vw;
  mask-size: cover;
  -webkit-mask-size: cover;
  background-color: var(--referral-program-panel-btn-text, #000);
  display: block;
}
.referral-code-panel__btn > .referral-code-panel__btn-img {
  margin-left: 0.8vw;
  flex: none;
}
.referral-code-panel__btn + .referral-code-panel__btn {
  margin-left: 2.6666666667vw;
}
.code-panel-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 4vw 0 0;
}
.code-panel-area__code {
  font-size: 4.8vw;
  font-weight: 700;
  color: var(--referral-program-panel-code-text, #ffde1a);
}
.code-panel-area__icon {
  display: block;
  width: 4.8vw;
  height: 4.8vw;
  background-color: var(--referral-program-panel-code-icon, #ffde1a);
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 80%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: 80%;
  margin-left: 1.3333333333vw;
}
.referral-program-downline-board {
  padding: 6.4vw 2.6666666667vw 2.6666666667vw;
}
.referral-program-downline-board__downline {
  font-size: 13.3333333333vw;
  color: var(--referral-program-downline-board-title, #ffde1a);
}
.referral-program-downline-board__guide {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2vw;
  color: var(--referral-program-downline-board-text, #fff);
}
.referral-program-downline-board__guide img {
  display: block;
  width: 3.2vw;
  height: 3.2vw;
  margin-left: 1.3333333333vw;
}
.referral-program-downline-board__head + .referral-program-downline-board__ul {
  margin-top: 5.3333333333vw;
}
.referral-program-downline-board__li {
  padding: 3.4666666667vw 3.2vw 4vw;
  border-radius: 0.8vw;
  background-color: var(--referral-program-downline-board-li-bg, rgba(0, 0, 0, 0.7));
  display: flex;
  align-items: center;
}
.referral-program-downline-board__li + .referral-program-downline-board__li {
  margin-top: 1.6vw;
}
.referral-program-downline-board__li > .referral-program-downline-board__status {
  flex: 1;
}
.referral-program-downline-board__li > .referral-program-downline-board__btn {
  flex: none;
}
.referral-program-downline-board__status {
  text-align: right;
  font-weight: 700;
  font-size: 4.8vw;
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-downline-board__status--left {
  text-align: left;
}
.referral-program-downline-board__status[data-title]:before {
  content: attr(data-title);
  display: block;
  color: var(--referral-program-downline-board-text, #fff);
  font-size: 3.7333333333vw;
  text-align: left;
  margin-bottom: 0.8vw;
}
.referral-program-downline-board__btn {
  padding: 1.3333333333vw 3.2vw;
  background-color: var(--referral-program-panel-highlight, #ffde1a);
  color: var(--referral-program-panel-btn-text, #000);
  font-size: 3.2vw;
  border-radius: 26.4vw;
  border: none;
}
.referral-program-panel {
  padding: 6.4vw 2.6666666667vw 2.6666666667vw;
}
.referral-program-panel__title {
  font-weight: 700;
  font-size: 4.8vw;
  text-align: center;
  color: var(--referral-program-panel-title, #fff);
}
.referral-program-panel__title + .referral-program-panel__tier-row {
  margin-top: 5.8666666667vw;
}
.referral-program-panel__tier-row {
  display: flex;
  justify-content: center;
}
.referral-program-panel__tier-row > .referral-program-panel__tier-rate {
  flex: 1;
  background-color: var(--referral-program-rate-board-li-bg, #3d3d3d);
}
.referral-program-panel__tier-row > .referral-program-panel__tier-rate + .referral-program-panel__tier-rate {
  margin-left: 1.6vw;
}
.referral-program-panel__tier-rate {
  font-size: 5.3333333333vw;
  text-align: center;
  padding-bottom: 6.9333333333vw;
  border-radius: 0.8vw;
  overflow: hidden;
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-panel__tier-rate:nth-child(1)[data-head]:before {
  background: linear-gradient(180deg, #06aa6b, #14805e);
}
.referral-program-panel__tier-rate:nth-child(2)[data-head]:before {
  background: linear-gradient(180deg, #1dd08b, #2fa27e);
}
.referral-program-panel__tier-rate:nth-child(3)[data-head]:before {
  background: linear-gradient(180deg, #59d2a3, #5bb398);
}
.referral-program-panel__tier-rate[data-head]:before {
  content: attr(data-head);
  display: block;
  font-size: 3.4666666667vw;
  font-weight: 700;
  padding-top: 1.6vw;
  padding-bottom: 1.3333333333vw;
  margin-bottom: 5.3333333333vw;
  color: var(--referral-program-panel-title, #fff);
}
.referral-program-panel__tier-rate[data-suffix]:after {
  content: "(" attr(data-suffix) ")";
  display: block;
  font-size: 3.2vw;
  margin-top: 2.4vw;
  color: var(--referral-program-panel-muted, #b5b5b5);
}
.referral-program-tooltip {
  font-size: 3.2vw;
  padding: 3.4666666667vw 4.2666666667vw;
  border-radius: 1.3333333333vw;
  box-shadow: 0 0.5333333333vw 1.0666666667vw #0000004d;
  background-color: var(--referral-program-tooltip-bg, #4d4d4d);
  animation: _ngcontent-serverApp-c1309121045_fadeIn 0.5s forwards;
}
@keyframes _ngcontent-serverApp-c1309121045_fadeIn {
  0% {
      opacity: 0;
      transform: translateY(20%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}


.referral-program-promotion__banner {
  position: relative;
  z-index: 2;
}
.referral-program-promotion-banner {
  padding-bottom: 85.3333333333%;
  overflow: hidden;
}
.referral-program-promotion-banner__img {
  position: absolute;
  padding-bottom: 116.3636363636%;
  width: 100%;
  top: 0;
  z-index: 1;
}
.referral-program-promotion-banner__img img {
  position: absolute;
  width: 100%;
}



header {
  z-index: 6000;
}
.menu {
  z-index: 9000;
}
.pop-bg {
  z-index: 8000;
}
.pop-wrap,
.member-menu,
.searchpage {
  z-index: 9000;
}
.menu-mask,
.modal {
  z-index: 8000;
}
.modal-backdrop {
  z-index: 7000;
}
.launch-game-content {
  z-index: 8500;
}
.tips-display {
  z-index: 8501;
}
.loading-mask {
  z-index: 9003;
}
.loader-box {
  z-index: 9004 !important;
}
.rotating-pop {
  z-index: 9002;
}
#chat-widget-container {
  z-index: 9001 !important;
}
[_nghost-serverApp-c1404166611] {
  display: block;
}
.referral-program-promotion {
  font-family: Helvetica Neue, sans-serif;
  position: relative;
}
@keyframes _ngcontent-serverApp-c1404166611_fadeIn {
  0% {
      opacity: 0;
      transform: translateY(20%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.referral-program-promotion [animatable] {
  opacity: 0;
}
.referral-program-promotion [animatable="animated"] {
  animation: _ngcontent-serverApp-c1404166611_fadeIn 0.5s forwards;
}
.referral-program-promotion__bg {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.referral-program-promotion__bg img {
  width: 100%;
}
.referral-program-promotion__body {
  padding: 0 2.6666666667vw;
}
.referral-program-promotion__banner {
  position: relative;
  z-index: 2;
}
.referral-program-promotion__panel + .referral-program-promotion__panel {
  margin-top: 3.2vw;
}
.referral-program-promotion-panel {
  background: var(--referral-program-panel-bg, #333);
  box-shadow: var(--referral-program-panel-shadow, 0px 2px 4px rgba(0, 0, 0, 0.5));
  border: 1px solid var(--referral-program-panel-border, #666);
  padding: 6.4vw 2.6666666667vw 8.5333333333vw;
  border-radius: 1.3333333333vw;
}
.referral-program-promotion-panel__title {
  font-weight: 700;
  font-size: 4.8vw;
  color: var(--referral-program-panel-title, #fff);
  text-align: center;
  line-height: normal;
}
.referral-program-promotion-panel__subheading {
  font-size: 4.2666666667vw;
  font-weight: 700;
  color: var(--referral-program-panel-highlight, #ffde1a);
}
.referral-program-promotion-panel__btn {
  border: none;
  background: var(--referral-program-text-highlight, #ffde1a);
  font-size: 3.7333333333vw;
  text-align: center;
  color: var(--referral-program-panel-btn-text, #000);
  padding: 3.7333333333vw 3.7333333333vw 4.5333333333vw;
  width: 100%;
  border-radius: 1.3333333333vw;
  font-weight: 700;
}
.referral-program-promotion-banner {
  padding-bottom: 69.8666666667%;
  overflow: hidden;
}
.referral-program-promotion-banner__logo {
  height: 8vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 9.6vw;
  margin: 0 auto;
}
.referral-program-promotion-banner__logo img {
  height: 100%;
}
.referral-program-promotion-banner__title {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20vw;
}
.referral-program-promotion-banner__title-fragment {
  display: block;
  white-space: nowrap;
  text-align: center;
  text-shadow: 0vw 0.5333333333vw 1.0666666667vw #000;
  font-size: 7.7333333333vw;
  line-height: 1.2em;
  font-weight: 900;
}
.referral-program-promotion-banner__title-fragmenthtml[lang="bn"][_nghost-serverApp-c1404166611] .referral-program-promotion-banner__title-fragment,
html[lang="bn"] [_nghost-serverApp-c1404166611] .referral-program-promotion-banner__title-fragment,
.referral-program-promotion-banner__title-fragmenthtml[lang="hi"][_nghost-serverApp-c1404166611] .referral-program-promotion-banner__title-fragment,
html[lang="hi"] [_nghost-serverApp-c1404166611] .referral-program-promotion-banner__title-fragment {
  line-height: 1.6em;
}
.referral-program-promotion-banner__title-fragment--highlight {
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-promotion-banner__img {
  position: absolute;
  padding-bottom: 116.3636363636%;
  width: 100%;
  top: 0;
}
.referral-program-promotion-banner__img img {
  position: absolute;
  top: -6.6666666667vw;
  width: 100%;
}
.referral-program-promotion-reward__title + .referral-program-promotion-reward__content {
  margin-top: 6.4vw;
}
.referral-program-promotion-reward__description {
  color: var(--referral-program-panel-muted, #b5b5b5);
  font-size: 3.7333333333vw;
  line-height: 1.429;
}
.referral-program-promotion-reward__description + .referral-program-promotion-reward__ratio-board {
  margin-top: 5.3333333333vw;
}
.referral-program-promotion-reward__description ~ .referral-program-promotion-reward__subheading {
  margin-top: 6.4vw;
}
.referral-program-promotion-reward__subheading + .referral-program-promotion-reward__tier-block {
  margin-top: 5.3333333333vw;
}
.referral-program-promotion-reward__tiers {
  max-width: 61.6393442623%;
  position: relative;
  z-index: 2;
}
.referral-program-promotion-reward__tier {
  position: relative;
  padding-bottom: 1.6vw;
}
.referral-program-promotion-reward__tier:after {
  content: "";
  display: block;
  width: 100%;
  height: 0.2666666667vw;
  background: linear-gradient(90deg, #fff0, #09c58a);
  margin-top: 1.8666666667vw;
}
.referral-program-promotion-reward__tier:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--referral-program-text-highlight, #ffde1a);
  width: 2.6666666667vw;
  height: 2.6666666667vw;
  border-radius: 50%;
  box-shadow: 0 0 1.0666666667vw 1.0666666667vw #14805e;
  border: 0.2666666667vw solid #14805e;
}
.referral-program-promotion-reward__tier-title {
  font-size: 3.7333333333vw;
  font-weight: 500;
  color: var(--referral-program-title, #fff);
}
.referral-program-promotion-reward__tier-rate {
  font-size: 4.8vw;
  font-weight: 700;
  color: var(--referral-program-text-highlight, #ffde1a);
  line-height: 1.5;
  vertical-align: text-bottom;
}
.referral-program-promotion-reward__tier-rate:after {
  content: "(" attr(data-suffix) ")";
  color: var(--referral-program-panel-muted, #b5b5b5);
  font-size: 3.2vw;
  vertical-align: text-bottom;
}
.referral-program-promotion-reward__content + .referral-program-promotion-reward__btn {
  margin-top: 9.8666666667vw;
}
.referral-program-promotion-reward__tier-block {
  position: relative;
}
.referral-program-promotion-reward__tier-block .referral-program-promotion-reward__chart {
  position: absolute;
  width: 46.4vw;
  right: 0;
  top: 0;
  z-index: 1;
}
.referral-program-promotion-chart__img {
  width: 11.4666666667vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}
.referral-program-promotion-chart__img img {
  width: 100%;
}
.referral-program-promotion-chart__circle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}
.referral-program-promotion-chart__circle:after {
  content: attr(data-text);
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 5.3333333333vw;
  color: #ffffff80;
  font-size: 12px;
  transform: scale(0.8);
  transform-origin: 50% 50%;
  text-align: center;
}
.referral-program-promotion-chart__circle-inner {
  position: relative;
  padding: 0 0 100%;
  background: #14805e;
  border-radius: 50%;
  box-shadow: 0 4.5px 3.6px #00000006, 0 12.5px 10px #00000009, 0 30.1px 24.1px #0000000c, 0 100px 80px #00000012;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.referral-program-promotion-chart__circle-inner:before {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
  border-radius: 50%;
  padding: 0.2666666667vw;
  background: linear-gradient(0deg, #fff0, #fff9) border-box;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: exclude;
  pointer-events: none;
}
.referral-program-promotion-chart__circle-inner:after {
  pointer-events: none;
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 0.2666666667vw solid transparent;
  box-shadow: none;
}
.referral-program-promotion-chart__circle--first {
  width: 52.2988505747%;
}
.referral-program-promotion-chart__circle--second {
  width: 74.7126436782%;
}
.referral-program-promotion-chart__circle--third {
  width: 100%;
}
.referral-program-promotion-chart__circle--first .referral-program-promotion-chart__circle-inner {
  opacity: 0.6;
}
.referral-program-promotion-chart__circle--second .referral-program-promotion-chart__circle-inner {
  opacity: 0.4;
}
.referral-program-promotion-chart__circle--third .referral-program-promotion-chart__circle-inner {
  opacity: 0.2;
}
.referral-program-promotion-guide__title + .referral-program-promotion-guide__flowch {
  margin-top: 22.6666666667vw;
}
.referral-program-promotion-guide__flowch + .referral-program-promotion-guide__btn {
  margin-top: 6.4vw;
}
.referral-program-promotion-step-block {
  position: relative;
}
.referral-program-promotion-step-block--left {
  text-align: left;
}
.referral-program-promotion-step-block--right {
  text-align: right;
}
.referral-program-promotion-step-block__img {
  position: absolute;
  z-index: 3;
}
.referral-program-promotion-step-block__img--a {
  width: 30.6666666667vw;
  right: 8vw;
  bottom: 5.3333333333vw;
}
.referral-program-promotion-step-block__img--b {
  width: 29.3333333333vw;
  left: 8vw;
  bottom: 5.3333333333vw;
}
.referral-program-promotion-step-block__img--c {
  width: 28vw;
  right: 8vw;
  bottom: 10.9333333333vw;
}
.referral-program-promotion-step-block__img img {
  width: 100%;
}
.referral-program-promotion-step-block__num {
  color: var(--referral-program-text-highlight, #ffde1a);
  font-size: 26.6666666667vw;
  font-weight: 700;
  position: absolute;
  z-index: 1;
  transform: translateY(-100%) translateY(3.7333333333vw);
  top: 0;
}
.referral-program-promotion-step-block__num--right {
  right: 7.7333333333vw;
}
.referral-program-promotion-step-block--left .referral-program-promotion-step-block__num {
  left: 7.7333333333vw;
}
.referral-program-promotion-step-block--right .referral-program-promotion-step-block__num {
  right: 7.7333333333vw;
}
.referral-program-promotion-step-block__body {
  position: relative;
  z-index: 2;
  padding: 5.3333333333vw 4.2666666667vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 1.3333333333vw;
  background: var(--referral-program-step-block-bg, rgba(255, 255, 255, 0.03));
  overflow: hidden;
}
.referral-program-promotion-step-block__body .referral-program-promotion-step-block__arrow {
  position: absolute;
}
.referral-program-promotion-step-block__arrow {
  width: 18.6666666667vw;
  transform-origin: 50% 50%;
  filter: blur(2px);
}
.referral-program-promotion-step-block__arrow--a1 {
  left: 36.5333333333vw;
  top: 7.2vw;
  opacity: 0.05;
}
.referral-program-promotion-step-block__arrow--a2 {
  right: -3.2vw;
  bottom: 2.1333333333vw;
  opacity: 0.03;
}
.referral-program-promotion-step-block__arrow--b1 {
  right: 75.4666666667vw;
  bottom: 6.4vw;
  opacity: 0.02;
}
.referral-program-promotion-step-block__arrow--b2 {
  left: 32.2666666667vw;
  top: 6.1333333333vw;
  transform: scaleX(-1);
  opacity: 0.04;
}
.referral-program-promotion-step-block__arrow--c1 {
  top: 6.4vw;
  left: -3.2vw;
  opacity: 0.06;
}
.referral-program-promotion-step-block__arrow--c2 {
  left: 40.2666666667vw;
  top: -4vw;
  transform: scaleX(-1);
  opacity: 0.03;
}
.referral-program-promotion-step-block__arrow--c3 {
  right: -4vw;
  bottom: 1.8666666667vw;
  transform: scaleX(-1);
  opacity: 0.02;
}
.referral-program-promotion-step-block__arrow img {
  width: 100%;
}
.referral-program-promotion-step-block__title {
  font-size: 4.2666666667vw;
  font-weight: 700;
  line-height: 1.5;
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-promotion-step-block__title + .referral-program-promotion-step-block__descrp {
  margin-top: 2.1333333333vw;
}
.referral-program-promotion-step-block__descrp {
  font-weight: 400;
  font-size: 3.2vw;
  color: #fff;
}
.referral-program-promotion-flowch__step + .referral-program-promotion-flowch__step {
  margin-top: 19.7333333333vw;
}
.referral-program-promotion-leaderboard__title + .referral-program-promotion-leaderboard__bo3 {
  margin-top: 10.9333333333vw;
}
.referral-program-promotion-leaderboard__title + .referral-program-promotion-leaderboard__subheading,
.referral-program-promotion-leaderboard__bo3 + .referral-program-promotion-leaderboard__subheading {
  margin-top: 8.5333333333vw;
}
.referral-program-promotion-leaderboard__subheading {
  text-align: center;
}
.referral-program-promotion-leaderboard__list {
  margin-top: 5.3333333333vw;
}
.referral-program-promotion-bo3 {
  display: flex;
  align-items: flex-start;
}
.referral-program-promotion-bo3__card + .referral-program-promotion-bo3__card {
  margin-left: 1.6vw;
}
.referral-program-promotion-bo3__card {
  flex: 1;
  margin-top: 5.8666666667vw;
}
.referral-program-promotion-bo3__card--r1 {
  margin-top: 0;
}
.referral-program-promotion-bo3card {
  position: relative;
}
.referral-program-promotion-bo3card:after {
  content: "";
  display: block;
  padding-bottom: 145.4545454545%;
}
.referral-program-promotion-bo3card > .referral-program-promotion-bo3card__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 1.3333333333vw;
  overflow: hidden;
  z-index: 1;
}
.referral-program-promotion-bo3card > .referral-program-promotion-bo3card__bg img {
  width: 100%;
}
.referral-program-promotion-bo3card__title {
  position: absolute;
  display: inline-block;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -40%);
  padding: 0.5333333333vw 1.8666666667vw;
  background: var(--referral-program-text-highlight, #ffde1a);
  border-radius: 26.4vw;
}
.referral-program-promotion-bo3card__title span {
  font-size: 3.2vw;
  font-weight: 700;
  text-align: center;
  color: var(--referral-program-panel-btn-text, #000);
  white-space: nowrap;
}
.referral-program-promotion-bo3card__title .referral-program-promotion-bo3card__deco {
  position: absolute;
  left: 50%;
  top: 0;
  width: 18px;
  transform: translate(-50%, -100%) translateY(0.5333333333vw);
}
.referral-program-promotion-bo3card__deco img {
  width: 100%;
}
.referral-program-promotion-bo3card > .referral-program-promotion-bo3card__content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.referral-program-promotion-bo3card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.4vw;
}
.referral-program-promotion-bo3card__avatar {
  width: 17.0666666667vw;
  background: var(--referral-program-panel-bg, #333);
  border-radius: 50%;
  position: relative;
}
.referral-program-promotion-bo3card__avatar:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.referral-program-promotion-bo3card__avatar img {
  position: absolute;
  width: 100%;
  left: 0;
}
.referral-program-promotion-bo3card__avatar + .referral-program-promotion-bo3card__name,
.referral-program-promotion-bo3card__avatar + .referral-program-promotion-bo3card__bonus {
  margin-top: 5.3333333333vw;
}
.referral-program-promotion-bo3card__name + .referral-program-promotion-bo3card__bonus {
  margin-top: 1.0666666667vw;
}
.referral-program-promotion-bo3card__name {
  color: #fff;
  font-size: 3.2vw;
  font-weight: 400;
  text-align: center;
}
.referral-program-promotion-bo3card__bonus {
  color: var(--referral-program-text-highlight, #ffde1a);
  font-weight: 700;
  text-align: center;
  font-size: 3.2vw;
}
.referral-program-promotion-leaderboard-list {
  display: table;
  width: 100%;
  border-radius: 1.3333333333vw;
  overflow: hidden;
  min-height: 122.6666666667vw;
}
.referral-program-promotion-leaderboard-list__item-row {
  display: table-row;
}
.referral-program-promotion-leaderboard-list__item-row:nth-child(odd) {
  background: var(--referral-leaderboard-row-1, #262626);
}
.referral-program-promotion-leaderboard-list__item-row:nth-child(2n) {
  background: var(--referral-leaderboard-row-2, #333);
}
.referral-program-promotion-leaderboard-list__item-row > .referral-program-promotion-leaderboard-list__item-cell {
  flex: 1;
}
.referral-program-promotion-leaderboard-list__item-cell + .referral-program-promotion-leaderboard-list__item-cell {
  position: relative;
}
.referral-program-promotion-leaderboard-list__item-cell + .referral-program-promotion-leaderboard-list__item-cell:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 60%;
  top: 50%;
  left: 0;
  opacity: 0.2;
  transform: translateY(-50%);
  border-left: 1px dashed #fff;
}
.referral-program-promotion-leaderboard-list__item-cell {
  display: table-cell;
  line-height: 1.2em;
  vertical-align: middle;
}
.referral-program-promotion-leaderboard-list__item-cell:nth-child(1) {
  padding: 3.4666666667vw 5.6vw;
  text-align: left;
}
.referral-program-promotion-leaderboard-list__item-cell:nth-child(2) {
  padding: 3.4666666667vw;
  text-align: center;
}
.referral-program-promotion-leaderboard-list__item-cell:nth-child(3) {
  padding: 3.4666666667vw 3.7333333333vw;
  text-align: right;
}
.referral-program-promotion-leaderboard-list__item-name {
  font-size: 3.2vw;
  color: var(--referral-program-panel-muted, #b5b5b5);
}
.referral-program-promotion-leaderboard-list__item-descrp {
  font-size: 2.9333333333vw;
  color: var(--referral-program-panel-muted, #b5b5b5);
}
.referral-program-promotion-leaderboard-list__item-bonus {
  text-align: end;
  font-size: 3.2vw;
  color: var(--referral-program-text-highlight, #ffde1a);
}
.referral-program-promotion-leaderboard-list__item-bonus[data-unit]:after {
  content: attr(data-unit);
  display: inline-block;
  font-size: 3.2vw;
  color: var(--referral-program-panel-muted, #b5b5b5);
  margin-left: 1.0666666667vw;
}
.rule-btn {
  width: 100%;
  margin: 5.3333333333vw 0;
  border: 1px solid var(--referral-program-panel-border, #666);
  background: transparent;
  border-radius: 0.8vw;
  line-height: 9.3333333333vw;
  color: var(--referral-program-panel-muted, #b5b5b5);
}
.game-lunch{
  width: 100%;
  height: 100%;
}
/* Modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  display: none;
  width: 95%;
  max-width: 500px;
  background-color: #111;
  border-radius: 0.3rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  overflow: hidden;
}

/* Show the modal */
.modal.show {
  display: block;
}

/* Modal header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
border: none;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  
  backdrop-filter: blur(5px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); 

}

.modal-header .btn-close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}

/* SVG Icon for the close button */
.modal-header .btn-close::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 16 16"%3E%3Cpath d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E');
  background-size: cover;
  transform: translate(-50%, -50%);
}


/* Modal title */
.modal-title {
  margin: 0;
  line-height: 1.5;
  font-size: 1.25rem;
}

/* Modal body */
.modal-body {
  padding: 1rem;
  
}
.modal-header {
  background: #ff8a14;
}

/* Modal footer */
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}

.modal-footer .btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: pointer;
}

.modal-footer .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.modal-footer .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.title{
  text-transform: capitalize
  
}
.item.type{
  text-transform: capitalize;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100%; 
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #666; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
  display: block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

body {
  background: #191e32;
  background-repeat: no-repeat;
  color: #fff!important;
  font-size: 18px!important
}

button {
  cursor: pointer
}

.red {
  color: red;
  line-height: 1;
  margin-left: 100px
}

.txtRed p {
  color: #ff0000!important
}

@media (max-width: 980px) {
  body {
      padding-top:50px
  }
}

.single-post h1 {
  margin-top: 30px
}

#main-content {
  background: transparent
}

.clearfix {
  overflow: hidden
}

.clearfix::after {
  content: "";
  clear: both;
  display: table
}

h2 {
  color: #fff;
  font-weight: bold;
  font-size: 35px
}

h3 {
  color: #fff;
  font-weight: bold;
  font-size: 26px
}

p {
  color: #fff;
  font-size: 18px;
  line-height: 35px
}

a,a:hover {
  color: #b99533
}

@media (max-width: 980px) {
  h2 {
      color:#fff;
      font-weight: bold;
      font-size: 22px
  }

  h3 {
      color: #fff;
      font-weight: bold;
      font-size: 18px
  }

  p {
      color: #cbcbcb;
      font-size: 16px;
      line-height: 30px
  }
}

.announcment {
  height: 40px;
  position: relative;
  width: 100%;
  background: rgb(201 163 60)
}

.announcment a.mcw-test {
  cursor: pointer;
  position: absolute;
  height: 40px;
  width: 100%;
  left: 0;
  z-index: 3
}

.announcment .aimg {
  height: 40px;
  width: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #d4b665
}

.announcment .aimg img {
  width: 30px;
  margin-top: 5px
}

.acontent {
  height: 40px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 10px center;
  color: #fff;
  padding: 6px;
  font-weight: bold
}

.acontent:hover {
  color: #fff
}

.acontent ul {
  list-style: none
}

.acontent ul li {
  color: #fff;
  display: inline;
  margin-right: 20px;
  line-height: 40px
}

.acontent ul li a {
  color: #fff
}

.mcw-dmenu ul li:nth-child(3),.mcwr-drawer ul li:nth-child(3) {
  background: linear-gradient(to right,#a56c0b 0,#d4b665 15%,#a56c0b 55%,#a56c0b 100%);
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-position: 50%;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(6,8,15,.1),inset 0 0 3px 0 #ffdd8f;
  font-weight: 600
}

.mcw-dmenu ul li:nth-child(3):hover,.mcwr-drawer ul li:nth-child(3):hover {
  color: #fff!important;
  background: linear-gradient(to right,#a56c0b 0,#bda35e 15%,#885909 55%,#8a5905 100%);
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-position: 50%;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(6,8,15,.1),inset 0 0 3px 0 #ffdd8f
}

header.et-l.et-l--header {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0
}

.mcwr-top {
}

.mcwr-top .time {
  width: 15%;
  float: left;
  font-size: 14px;
  color: #fff;
  line-height: 33px
}

.mcwr-top .language {
  width: 20%;
  float: left
}

.mcwr-top .support {
  width: 50%;
  float: right
}

.mcwr-top .support ul {
  list-style: none;
  width: auto;
  float: right;
  padding: 0;
  margin: 5px 0
}

.mcwr-top .support ul li {
  font-size: 14px;
  display: inline-block;
  color: #fff;
  border-left: 1px solid rgba(255,255,255,0.3);
  padding: 0 10px
}

.mcwr-top .support ul li:first-child {
  border-left: 0
}

.mcwr-top .support ul li a {
  color: #fff;
  cursor: pointer
}

.mcwr-top .support ul li a img {
  height: 15px;
  margin-right: 5px;
  position: relative;
  top: 3px
}

.logo a {
  cursor: pointer
}

.mcw-mainnav {
  padding: 10px 0
}

.mcw-mainnav .logo {
  width: 50%;
  float: left
}

.mcw-mainnav .logo span {
  display: inline-block;
  width: auto;
  padding-right: 10px
}

.mcw-mainnav .logo span img {
  height: 30px;
  margin-top: 10px
}

.mcw-mainnav .button {
  width: 50%;
  float: left;
  text-align: right
}

.mcw-mainnav .button .login {
  background: linear-gradient(to right,#2a3254 0,#445187 15%,#445187 30%,#2a3254 55%,#2a3254 100%);
  background-position: 50%0;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(25,30,50,.3),inset 0 0 3px 0#222843;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  line-height: 30px;
  padding: 0 1.875rem;
  display: inline-block;
  margin-top: 10px
}

.mcw-mainnav .button .telegram {
  background: linear-gradient(to right,#a56c0b 0,#d4b665 15%,#d4b665 30%,#a56c0b 55%,#a56c0b 100%);
  background-position: 50%0;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(25,30,50,.3),inset 0 0 3px 0#222843;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  line-height: 30px;
  padding: 0 1.875rem;
  display: inline-block;
  margin-top: 10px
}

.mcw-mainnav .button .email {
  background: linear-gradient(to right,#a56c0b 0,#d4b665 15%,#d4b665 30%,#a56c0b 55%,#a56c0b 100%);
  background-position: 50%0;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(25,30,50,.3),inset 0 0 3px 0#222843;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  line-height: 30px;
  padding: 0 1.875rem;
  display: inline-block;
  margin-top: 10px
}

.mcw-mainnav .button .register {
  background: linear-gradient(to right,#a56c0b 0,#d4b665 15%,#d4b665 30%,#a56c0b 55%,#a56c0b 100%);
  background-position: 50%0;
  background-size: 200%;
  box-shadow: 0 5px 10px 0 rgba(6,8,15,.1),inset 0 0 3px 0#ffdd8f;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  line-height: 30px;
  padding: 0 1.875rem;
  display: inline-block;
  margin-top: 10px
}

.mcw-rmobile {
  padding: 8px 0 0 0
}

.mcw-rmobile .burger {
  width: 25%;
  float: left
}

.mcw-rmobile .burger a {
  display: block;
  height: 100%;
  width: 32%;
  margin-left: 15px;
  padding-top: 9px;
  padding-bottom: 9px
}

.mcw-rmobile .burger a span {
  display: block;
  height: 2px;
  border-radius: 50px;
  margin-top: 3px;
  width: 60%;
  background-color: #c9a33d;
  transition: all 0.2s ease
}

.mcw-rmobile .burger a span:first-child {
  width: 70%
}

.mcw-rmobile .burger a span:last-child {
  width: 50%
}

.mcw-rmobile .burger.active a span {
  display: none
}

.mcw-rmobile .burger.active a span:first-child {
  transform: rotate(40deg);
  position: relative;
  top: 5px;
  display: block
}

.mcw-rmobile .burger.active a span:last-child {
  transform: rotate(-40deg);
  position: relative;
  top: 0px;
  display: block;
  width: 70%
}

.mcw-rmobile .logo {
  width: 50%;
  float: left
}

.mcw-rmobile .logo span {
  display: block;
  text-align: center
}

.mcw-rmobile .logo span img {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 5px
}

.mcw-rmobile .support {
  width: 25%;
  float: left;
  padding-right: 5px
}

.mcw-rmobile .support a {
  display: block;
  text-align: center;
  padding-top: 2px;
  text-indent: 25px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 10px;
  font-weight: bold
}

.mcw-rmobile .support a img {
  width: 25px!important;
  margin: 0 auto 0 auto!important;
  display: block;
  padding-bottom: 2px!important
}

.mcw-rmobile .support a span {
  display: block;
  font-size: 10px;
  font-weight: bold;
  color: #bea147;
  line-height: 0px
}

@media (max-width: 329px) {
  .mcw-rmobile .support a img {
      margin-top:8px
  }
}

.mcwr-drawer {
}

.et_pb_row.et_pb_row_3_tb_header {
  margin: 0;
  width: 100%
}

.mcwr-drawer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 10px solid #111421
}

.mcwr-drawer ul li {
  padding: 10px 0 0 0
}

.mcwr-drawer ul li:last-child {
  border-bottom: 1px solid rgba(255,255,255,0.1)
}

.mcwr-drawer ul li a {
  display: block;
  width: 100%;
  margin-left: 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: #fff;
  padding-bottom: 10px;
  font-weight: bold;
  text-indent: 15px
}

.mcwr-drawer ul li a img {
  width: 33px;
  position: relative;
  top: -3px;
  margin-right: 10px;
  float: left
}

.mcwr-drawer .support {
  background: #272e4d;
  border-radius: 3px;
  margin: 10px;
  color: #fff
}

.mcwr-drawer .support .icon {
  width: 30%;
  float: left;
  text-align: center;
  padding: 18px 0 0 0
}

.mcwr-drawer .support .icon img {
  width: 33px
}

.mcwr-drawer .support .text {
  width: 70%;
  float: left;
  padding: 10px 10px 10px 0
}

.mcwr-drawer .support .text p {
  padding: 0;
  font-size: 14px;
  color: #fff
}

.mcwr-drawer .support .text span {
  font-size: 10px;
  color: #fff;
  display: block;
  line-height: 13px
}

.et_pb_section_4_tb_header.et_pb_section {
  position: fixed;
  top: 51px;
  height: 100%;
  background: #191e32;
  z-index: 4;
  width: 100%;
  left: -100%;
  transition: all 0.2s ease
}

.et_pb_section_4_tb_header.et_pb_section.active {
  left: 0
}

.mcw-dmenu {
}

.mcw-dmenu ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.mcw-dmenu ul li {
  padding: 10px 0 12px 0;
  float: left
}

.mcw-dmenu ul li a {
  color: #fff;
  font-size: 14px;
  padding: 4px 40px;
  display: block;
  line-height: 20px;
  position: relative
}

.mcw-dmenu ul li a:after {
  content: '';
  position: absolute;
  height: 14px;
  width: 1px;
  display: block;
  left: 0;
  top: 7px;
  background: #fff
}

.mcw-dmenu ul li:hover {
  background: rgba(0,0,0,0.2);
  position: relative
}

.mcw-dmenu ul li:hover:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 3px;
  background: #c9a33d;
  display: block
}

.mcw-dmenu ul li:hover a {
  color: #c9a33d
}

.mcw-dmenu ul li:first-child:hover {
  background: rgba(0,0,0,0)
}

.mcw-dmenu ul li:first-child:hover:after {
  display: none
}

.mcw-dmenu ul li:first-child a {
  padding: 0 20px 0 0
}

.mcw-dmenu ul li:first-child a:after {
  display: none
}

.mcw-dmenu ul li a img {
  height: 20px;
  width: 20px;
  position: relative;
  top: 1px
}

.et_pb_section_0_tb_header {
  z-index: 3
}

@media (max-width: 980px) {
  .et_pb_row_4_tb_header {
      width:100%
  }
}

@media (max-width: 370px) {
  .mcwrmm ul li:first-child>a.ftrigger>img {
      position:absolute!important;
      left: 32%;
      top: 13px!important;
      width: 40px!important
  }

  .mcwrmm>ul>li:first-child>a {
      text-indent: 100%;
      height: 48px;
      overflow: hidden;
      color: #c9a33d
  }
}

.mcw-mainnav .language {
  width: fit-content;
  float: right
}

.mcw-mainnav .wpml-ls-statics-shortcode_actions {
  width: 12em;
  float: right;
  margin: 7px 0 0 0
}

.mcw-mainnav .wpml-ls-statics-shortcode_actions>ul {
  padding: 0
}

.wpml-ls-legacy-dropdown-click a,.wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover>a,.wpml-ls-legacy-dropdown-click a:focus,.wpml-ls-legacy-dropdown-click a:hover {
  display: block;
  text-decoration: none;
  color: #b99533;
  border: none;
  background-color: transparent;
  padding: 5px;
  line-height: 1;
  border-radius: 5px
}

.wpml_desktop .wpml-ls-sub-menu a {
  padding: 10px
}

.wpml_desktop .wpml-ls-sub-menu ul {
  margin: 0;
  padding: 0
}

.wpml_desktop .wpml-ls-legacy-dropdown a span {
  color: #fff;
  font-size: 14px
}

.wpml_desktop .mcw-mainnav .wpml-ls-statics-shortcode_actions {
  margin: 4px 0 0 0
}

.wpml_desktop .wpml-ls-legacy-dropdown .wpml-ls-item {
  margin: 0 4px 0
}

.wpml_desktop .wpml-ls-legacy-dropdown a {
  display: block;
  text-decoration: none;
  color: #fff!important;
  border: 1px solid #B4862D;
  background-color: #B4862D;
  display: inline-block;
  width: 100%;
  text-align: left;
  border: none;
  line-height: 1;
  border-radius: 5px
}

.wpml_desktop .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a,.wpml_desktop .wpml-ls-legacy-dropdown a:focus,.wpml_desktop .wpml-ls-legacy-dropdown a:hover {
  display: block;
  text-decoration: none;
  color: #fff!important;
  border: 1px solid #B4862D;
  background-color: #B4862D;
  display: inline-block;
  width: 100%;
  text-align: left;
  border: none;
  line-height: 1;
  border-radius: 5px
}

.wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle::after {
  display: none
}

.wpml_desktop .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
  border-top: none
}

.wpml-ls-statics-shortcode_actions ul {
  margin: 0px!important;
  padding: 0px!important
}

.wpml_mobile .wpml-ls-legacy-dropdown {
  width: 100%
}

.wpml_mobile .mcwrmm ul li {
  background: #c9a33d;
  width: 100%!important
}

.wpml_mobile .wpml-ls-legacy-dropdown a {
  box-shadow: none;
  border: none;
  line-height: 38px
}

.wpml_mobile .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a,.wpml_mobile .wpml-ls-legacy-dropdown a:focus,.wpml_mobile .wpml-ls-legacy-dropdown a:hover {
  box-shadow: none;
  border: none;
  background: #c9a33d;
  color: #fff
}

.wpml_mobile .wpml-ls-legacy-dropdown a span {
  color: #fff
}

.wpml_mobile .wpml-ls-legacy-dropdown .wpml-ls-item {
  margin: 0
}

.wpml_mobile .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
  top: -87px;
  border-top: none
}

@media only screen and (max-width: 980px) {
  .mcw-rmobile .support {
      display:flex;
      justify-content: end;
      align-items: center
  }

  .wpml-ls-legacy-dropdown-click,.wpml-ls-legacy-dropdown-click .wpml-ls-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0
  }

  .wpml-ls-legacy-dropdown-click a,.wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover>a,.wpml-ls-legacy-dropdown-click a:focus,.wpml-ls-legacy-dropdown-click a:hover {
      display: block;
      text-decoration: none;
      color: #fff;
      border: none;
      background-color: transparent!important;
      padding: 5px 10px;
      line-height: 1;
      border-radius: 5px;
      position: relative
  }

  .wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle::after {
      display: none
  }

  .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu {
      border-top: none;
      background-color: #121626
  }

  .mcw-rmobile .support a img {
      width: 40px;
      padding-bottom: 15px
  }

  .mcw-rmobile .support a {
      text-indent: 0;
      margin-bottom: 15px
  }

  .wpml-ls-legacy-dropdown-click a.wpml-ls-item-toggle {
      padding-right: 0;
      margin-bottom: 0
  }

  .wpml-ls-legacy-dropdown-click a,.wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover>a,.wpml-ls-legacy-dropdown-click a:focus,.wpml-ls-legacy-dropdown-click a:hover {
      padding: 0
  }

  .wpml-ls-legacy-dropdown-click>ul {
      background-color: transparent!important;
      padding: 5px 0 0 0;
      width: 100px;
      right: -60px;
      position: relative
  }

  @media only screen and (max-width: 750px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:-35px;
          position: relative
      }
  }

  @media only screen and (max-width: 700px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:-40px;
          position: relative
      }
  }

  @media only screen and (max-width: 600px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:-30px;
          position: relative
      }
  }

  @media only screen and (max-width: 500px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:-15px;
          position: relative
      }
  }

  @media only screen and (max-width: 450px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:-5px;
          position: relative
      }
  }

  @media only screen and (max-width: 400px) {
      .wpml-ls-legacy-dropdown-click>ul {
          right:0;
          position: relative
      }
  }
}

.mcw-rf {
  padding: 20px 0;
  border-top: 1px solid rgba(255,255,255,0.2)
}

.mcw-rf p {
  font-size: 14px
}

.mcw-rf:first-child {
  border-top: 0px solid rgba(255,255,255,0.3)
}

.mcw-rf .cont {
  width: 25%;
  float: left
}

.mcw-rf .cont .imgs {
  width: 25%;
  float: left
}

.mcw-rf .cont .texts {
  width: 75%;
  float: left;
  padding: 20px 10px 0 10px
}

.mcw-rf .cont .texts h4 {
  color: #fff;
  font-size: 16px;
  padding-bottom: 15px
}

.mcw-rf .cont .texts p {
  color: #999;
  padding-bottom: 15px
}

.mcw-rf .cont .texts a {
  color: #c9a33d
}

.mcw-rf .cont .dla {
  -webkit-filter: grayscale(50%) opacity(70%);
  filter: grayscale(50%) opacity(70%)
}

.mcw-rf ul {
  padding: 0;
  list-style: none
}

.mcw-rf ul li {
  display: inline-block;
  margin-right: 20px
}

.mcw-rf ul li h4 {
  font-size: 10px;
  color: #fff
}

.mcw-rf ul li a {
  display: inline-block;
  margin-right: 15px
}

.mcw-rf ul li a img {
  height: 25px;
  -webkit-filter: saturate(0) brightness(90%) contrast(50%);
  filter: saturate(0) brightness(90%) contrast(50%)
}

.mcw-rf ul li a:hover img {
  -webkit-filter: grayscale(0) brightness(100%);
  filter: grayscale(0) brightness(100%)
}

.mcw-rf ul.ul2 {
}

.mcw-rf ul.ul2 li {
  width: 10%;
  margin-right: 0
}

.mcw-rf ul.ul2 li:first-child {
  width: 89%
}

.mcw-rf ul.ul2 li img {
  height: 30px
}

.mcw-rf ul.ul3 {
}

.mcw-rf ul.ul3 li {
  display: inline-block;
  margin-right: 0;
  width: 49%
}

.mcw-rf ul.ul3 li img {
  height: 30px;
  -webkit-filter: grayscale(0) brightness(100%);
  filter: grayscale(0) brightness(100%)
}

.mcw-rf ul.ul3 li p {
  text-align: right;
  margin-bottom: 0;
  padding-bottom: 0
}

.mcw-rf ul.ul3 li p a {
  color: #b99533;
  margin-right: 0;
  padding: 0
}

.mcw-rfm {
  padding: 20px 0;
  border-top: 1px solid rgba(255,255,255,0.2);
  width: 90%;
  margin: 0 5%
}

.mcw-rfm:first-child {
  padding: 20px 10px;
  border-top: 0px solid rgba(255,255,255,0.2)
}

.mcw-rfm h4 {
  font-size: 14px;
  font-weight: bold;
  color: #b99533
}

.mcw-rfm.grayc img {
  height: 25px;
  -webkit-filter: saturate(0) brightness(90%) contrast(50%);
  filter: saturate(0) brightness(90%) contrast(50%);
  margin-right: 10px
}

.mcw-rfm img {
  height: 30px
}

.mcw-rfm a {
  color: #b99533
}

.mcw-rfm ul {
  list-style: none;
  padding: 0
}

.mcw-rfm ul li {
  display: inline-block
}

.mcw-rfm ul li p {
  line-height: 12px;
  font-size: 12px;
  padding-bottom: 0
}

.mcw-rfm ul li p:first-child {
  color: #b99533;
  font-weight: bold
}

.mcwrmm {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0
}

.mcwrmm ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex
}

.mcwrmm ul li {
  flex-grow: 1;
  text-align: center;
  background: #000;
  color: #fff;
  position: relative;
  width: 33.33%
}

.mcwrmm ul li ul li {
  width: 100%
}

.mcwrmm ul li a {
  color: #fff;
  display: block;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  background: #191e32
}

.mcwrmm ul li:last-child a,.mcwrmm ul li:first-child a {
  background: #c9a33d
}

.language a.ftrigger img,.mcwrmm ul li:first-child a.ftrigger img {
  width: 30px;
  position: relative;
  top: 3px;
  margin-right: 10px
}

.language ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.language ul,.mcwrmm ul li:first-child ul {
  display: none
}

.mcwrmm ul li:first-child ul.active {
  display: block;
  position: absolute;
  top: -597px;
  width: 100%
}

.language ul.active {
  display: block;
  position: absolute;
  top: 100%;
  width: 160px;
  z-index: 1
}

.mcwrmm ul li:first-child ul.active li a {
  text-align: left;
  text-indent: 5px
}

.mcwrmm ul li ul li a {
  text-indent: 5px
}

.language ul.active li {
}

.language ul.active li a {
  text-indent: 5px;
  margin-bottom: 1px;
  display: block;
  color: #fff;
  background: #c9a33c
}

.language ul.active li a img,.mcwrmm ul li:first-child ul.active li a img {
  width: 30px;
  position: relative;
  top: 3px;
  margin-right: 10px
}

@media (max-width: 400px) {
  .mcwrmm ul li ul li {
      width:140%
  }
}

@media (max-width: 980px) {
  .et_pb_row_1_tb_footer {
      padding:0 0 40px 0
  }
}

body #page-container .et_pb_section .et_pb_button_0_tb_body {
  width: 100%;
  font-weight: bold;
  text-align: center;
  padding: 10px
}

body #page-container .et_pb_section .et_pb_button_1_tb_body {
  width: 90%;
  margin: 0 5% 30px 5%;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  color: #fff
}

#page-container {
  margin-top: 120px!important
}

#PostContent h3 {
  font-size: 18px;
  color: #d2b54e
}

#PostContent h2 {
  font-size: 22px;
  color: #d2b54e
}

.slick-dots {
  position: absolute!important;
  width: 100%;
  bottom: 10px
}

.mcw-com-contact {
  text-align: center;
  padding: 40px 0;
  font-weight: bold;
  border-top: 1px solid rgba(255,255,255,0.1)
}

.mcw-com-contact p {
  color: #fff
}

.mcw-com-contact a {
  background: #c49636;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-left: 20px
}

@media (max-width: 980px) {
  .mcw-com-contact a {
      display:block;
      margin: 20px auto 0 auto;
      width: 60%
  }
}

.post-template-default .et_pb_post_title {
  margin-bottom: 20px
}

.post-template-default .et_pb_title_container h1 {
  font-size: 35px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  color: #d2b54e!important
}

.post-template-default p {
  font-size: 16px
}

.tableStyle_2 {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom,#d2b54e,#ffc700) 1;
  padding: 10px
}

.tableStyle_2 .redBadge,.tableStyle_2 .orangeBadge {
  position: relative;
  width: 200px;
  height: 70px
}

.tableStyle_2 .redBadge img,.tableStyle_2 .orangeBadge img {
  position: absolute;
  left: 38px;
  top: -10px;
  width: 130px
}

.tableStyle_2 .redBadge span,.tableStyle_2 .orangeBadge span {
  position: relative;
  z-index: 999;
  font-weight: bold;
  top: 20px;
  text-align: center
}

.tableStyle_2 .orangeBadge span {
  font-size: 18px
}

.tableStyle_2 .redBadge span {
  font-size: 32px
}

.tableStyle_2 .redBg {
  background: #ca494b
}

.tableStyle_2 .textRed {
  color: #ee3945
}

.tableStyle_2 .textGold2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffcc00
}

.tableStyle_2 .textGold {
  font-size: 28px;
  font-weight: bold;
  color: #ffcc00
}

.tableStyle_2 .orangeBg {
  background: #e69e2c;
  color: #141620;
  margin-right: 2px;
  font-size: 24px;
  font-weight: bold
}

.tableStyle_2 .yellowBg {
  background: #ffc700;
  color: #141620;
  margin-right: 2px;
  font-size: 24px;
  font-weight: bold
}

.tableStyle_2 table {
  width: 100%
}

.tableStyle_2 tr:nth-child(even) {
  background-color: rgba(44,51,82,0.7)
}

.tableStyle_2 tr:nth-child(odd) {
  background-color: rgba(26,30,49,0.65)
}

.tableStyle_2 thead {
  margin-bottom: 2px
}

.tableStyle_2 thead td {
  background: #c3a24f;
  font-weight: bold;
  font-size: 18px!important
}

.tableStyle_2 table tr td,.tableStyle_2 table tr th {
  padding: 10px;
  font-size: 18px;
  color: #fff;
  text-align: center
}

@media only screen and (max-width: 880px) {
  .tableStyle_2 .et_pb_code_inner {
      overflow-x:scroll
  }

  .tableStyle_2 .redBadge img,.tableStyle_2 .orangeBadge img {
      left: 5px
  }

  .tableStyle_2 .redBadge span {
      font-size: 32px;
      left: -10px
  }

  .tableStyle_2 .redBadge span,.tableStyle_2 .orangeBadge span {
      top: 15px
  }
}

.tableStyle table {
  background: #d7b534;
  width: 100%
}

.tableStyle table img {
  display: inline;
  width: 40px;
  padding: 3px
}

.tableStyle thead {
  width: 100%;
  font-size: 22px;
  text-transform: uppercase
}

.tableStyle tbody tr:nth-child(odd) {
  background-color: #2b3354!important
}

.tableStyle table tr td a {
  cursor: pointer
}

.tableStyle table tr td,.tableStyle table tr th {
  padding: 10px;
  font-size: 14px;
  color: #fff;
  text-align: center
}

.post-template-default table {
  width: 100%
}

.post-template-default table tr td {
  width: 20%
}

.post-template-default.single.single-post table.table-0 tr td,.post-template-default.single.single-post table.table-0 tr td {
  width: 20%!important
}

.post-template-default.single.single-post table.table-1 tr td,.post-template-default.single.single-post table.table-2 tr td,.post-template-default.single.single-post table.table-3 tr td,.post-template-default.single.single-post table.table-1 tr td,.post-template-default.single.single-post table.table-2 tr td,.post-template-default.single.single-post table.table-3 tr td {
  width: 12.5%!important
}

.post-template-default.single.single-post table.table-1 tr td,.post-template-default.single.single-post table.table-2 tr td,.post-template-default.single.single-post table.table-3 tr td,.post-template-default.single.single-post table.table-1 tr td,.post-template-default.single.single-post table.table-2 tr td,.post-template-default.single.single-post table.table-3 tr td {
  width: 11.1%!important
}

.post-template-default table tr th {
  background: rgba(0,0,0,0.4);
  border: 1px solid rgba(255,255,255,0.1);
  padding: 10px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: bold
}

.post-template-default table tr td {
  background: rgba(0,0,0,0.4);
  border: 1px solid rgba(255,255,255,0.1);
  padding: 10px;
  font-size: 14px;
  color: #fff;
  text-align: center
}

.post-template-default .box1 {
  background: #b99533;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px
}

.post-template-default .box1 p {
  color: #fff;
  font-weight: bold;
  padding-bottom: 0;
  line-height: 24px
}

@media (max-width: 980px) {
  .tableStyle table tr td,.tableStyle table tr th {
      padding:10px 0
  }

  .post-template-default .et_pb_section h1 {
      font-size: 24px
  }
}

.section_whyPartnerUp .et_pb_blurb {
  height: 220px
}

.et_pb_accordion_item {
  padding: 0 0 20px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px dotted #504723
}

.et_pb_accordion_item h5 {
  color: #d0b758!important;
  font-weight: bold
}

.faq-menu {
  padding: 0!important;
  margin: 0;
  display: flex;
  list-style: none!important
}

.faq-menu li {
  flex-grow: 1;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 0 2px 0 0;
  background: rgba(0,0,0,0.3);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer
}

.faq-menu li.active {
  background: #b17f22;
  font-weight: bold
}

.et_pb_module.et_pb_accordion::before {
  content: '';
  display: none!important
}

@media (max-width: 980px) {
  #page-container {
      margin-top:60px!important
  }

  .faq-menu::after {
      content: "";
      clear: both;
      display: table
  }

  .faq-menu {
      display: block
  }

  .faq-menu li {
      float: left;
      width: 100%;
      margin: 0 5px 5px 0;
      font-size: 16px;
      padding: 10px 0;
      font-weight: normal
  }

  .faq-menu li.active {
      font-weight: normal
  }

  .et_pb_module.et_pb_accordion::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 82%;
      display: block;
      z-index: 2
  }
}

@media (max-width: 400px) {
  .faq-menu li {
      float:left;
      margin: 0 5px 5px 0;
      font-size: 14px;
      padding: 10px 0;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
  }

  span {
      word-wrap: normal
  }
}

.post-template-default .et_pb_column_0_tb_body.et_pb_column_4_4 {
  min-height: 800px
}

.space-text {
  padding-left: 30px
}

.postid-304.post-template-default .et_pb_title_container h1,#form_ContactUs.post-template-default .et_pb_title_container h1 {
  text-align: center;
  margin-top: 20px
}

.postid-304.post-template-default .et_pb_column_0_tb_body.et_pb_column_4_4,#form_ContactUs.post-template-default .et_pb_column_0_tb_body.et_pb_column_4_4 {
  min-height: 700px
}

.contact-dets {
  padding: 30px 0 10px 0;
  margin-right: 10px;
  border-radius: 5px
}

.c-dets {
  margin-bottom: 20px;
  max-width: 370px;
  margin: 0 auto
}

.c-dets .iimg {
  float: left;
  width: 20%
}

.c-dets .iimg img {
  height: auto
}

.c-dets .ttext {
  float: left;
  width: 55%;
  padding: 12px
}

.c-dets .ttext p {
  padding-bottom: 0;
  line-height: 24px
}

#form_ContactUs .elementor-form {
  background: rgba(0,0,0,0.3);
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px
}

#form_ContactUs .elementor-form label {
  font-size: 16px;
  color: #fff
}

#form_ContactUs .elementor-form input[type="text"],#form_ContactUs .elementor-form input[type="email"] {
  background-color: rgba(255,255,255,0.3)!important;
  border: 0;
  height: 50px;
  text-indent: 20px;
  color: #fff!important;
  margin-bottom: 10px
}

#form_ContactUs .elementor-form textarea {
  background-color: rgba(255,255,255,0.3)!important;
  border: 0;
  padding: 10px 20px;
  color: #fff!important;
  margin-bottom: 10px
}

#form_ContactUs .elementor-form button {
  height: 50px;
  font-size: 16px;
  background-color: #b99433!important
}

.postid-374 h1.entry-title,.postid-374 h3.elementor-heading-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  font-size: 3em
}

.postid-374 h1.entry-title:before,.postid-374 h3.elementor-heading-title:before {
  content: '';
  background: rgba(255,255,255,0.2);
  display: block;
  height: 1px;
  top: 50%;
  left: 0;
  position: absolute;
  width: 30%
}

.postid-374 h1.entry-title:after,.postid-374 h3.elementor-heading-title:after {
  content: '';
  background: rgba(255,255,255,0.2);
  display: block;
  height: 1px;
  top: 50%;
  right: 0;
  position: absolute;
  width: 30%
}

@media (max-width: 980px) {
  .et_pb_column .et_pb_row_inner,.et_pb_row {
      padding:30px 0 30px 0
  }

  .postid-374 h1.entry-title,.postid-374 h3.elementor-heading-title {
      font-size: 1.5em
  }

  .postid-374 h1.entry-title:before,.postid-374 h3.elementor-heading-title:before,.postid-374 h1.entry-title:after,.postid-374 h3.elementor-heading-title:after {
      display: none
  }

  .c-dets {
      max-width: 370px;
      margin: 0 auto
  }
}

.postid-212 .et_pb_toggle.et_pb_toggle_0,.postid-528 .et_pb_toggle.et_pb_toggle_0 {
  background: #0f121e;
  border: 0;
  border-radius: 5px;
  margin-bottom: 20px!important
}

.postid-212 .et_pb_toggle.et_pb_toggle_0 h5,.postid-528 .et_pb_toggle.et_pb_toggle_0 h5 {
  color: #fff;
  font-weight: bold;
  font-size: 18px
}

.section_banners h2 {
  color: #d7b533
}

.yellow-color {
  color: #d7b533
}

.section_48Lifetime h2 {
  font-weight: 900;
  font-style: italic;
  line-height: 40px
}

.home p {
  color: #fff
}

.section_contactUS .et_pb_button_0_tb_body {
  width: auto!important
}

.section_contactUS .et_pb_button_module_wrapper {
  text-align: center
}

@media (max-width: 920px) {
  .section_48Lifetime h2 {
      line-height:normal
  }

  .et_pb_text_4_tb_body h2 {
      font-size: 22px!important
  }
}

@media only screen and (max-width: 980px) {
  .section_whyPartnerUp h2 {
      font-size:22px!important
  }

  .two-col-mob .et_pb_column {
      width: 50%!important
  }
}

@media only screen and (max-width: 620px) {
  .section_whyPartnerUp h2 {
      font-size:16px!important
  }

  .section_whyPartnerUp .et_pb_main_blurb_image {
      margin-bottom: 10px
  }

  .section_whyPartnerUp .et_pb_blurb {
      padding: 10px;
      height: 150px
  }
}

@media only screen and (max-width: 420px) {
  .section_whyPartnerUp .et_pb_blurb_description p {
      line-height:22px!important
  }
}

.section_contactUS .button {
  width: 100%;
  float: left;
  text-align: center
}

.section_contactUS .button .login {
  background: #cda828;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.section_contactUS .button .telegram {
  background: #cda828;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.section_contactUS .button .email {
  background: #1f2442;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.section_contactUS .button .register {
  background: #a97925;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.learn-more {
  background: #bc922f;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.learn-more:hover {
  background: #bc922f;
  background-position: 50%0;
  background-size: 200%;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block;
  margin-bottom: 10px
}

.form_section h3 {
  font-size: 38px;
  font-weight: 700!important
}

.float-banner {
  position: fixed;
  left: 35px;
  bottom: 75px;
  z-index: 7999;
  -webkit-filter: drop-shadow(0 0 .375rem rgba(3,25,73,.7));
  filter: drop-shadow(0 0 .375rem rgba(3,25,73,.7));
  opacity: 1
}

.float-banner .btn-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: -.3125rem;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 100%;
  background-color: #911;
  opacity: 1
}

.float-banner .btn-close::after,.float-banner .btn-close::before {
  content: "";
  display: block;
  position: absolute;
  top: .6875rem;
  left: .3125rem;
  width: .9375rem;
  height: .125rem;
  border-radius: .125rem;
  background-color: #fff
}

.float-banner .btn-close::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.float-banner .btn-close::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.float-banner .close,.float-banner a {
  display: inline-flex;
  width: 9.0625rem;
  height: 9.0625rem;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain
}

@media only screen and (max-width: 420px) {
  .form_section h3 {
      font-size:24px
  }

  .float-banner {
      left: 15px
  }

  .float-banner .close,.float-banner a {
      width: 6.0625rem;
      height: 6.0625rem
  }
}

.TableStyle .title {
  background: #cda828;
  color: #fff
}

.TableStyle .even {
  background: #16192f
}

@media (max-width: 660px) {
  .post-template-default table tr td,.post-template-default table tr th {
      font-size:12px!important;
      padding: 3px
  }
}

.section_banners h2 {
  font-size: 30px!important
}

.commission_plan p {
  color: #fff!important
}

.ban_section h1 {
  color: #fff
}

.ban_section .imageBanner img {
  width: 100%
}

.ban_section .buttonLink {
  background: #a97925;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 1.870rem;
  display: inline-block
}

@media (max-width: 480px) {
  .ban_section .buttonLink {
      padding:0 0.370rem;
      display: inline
  }
}

.et_pb_toggle_title {
  text-transform: uppercase
}

.et_pb_module_header {
  font-size: 16px!important
}

.et_pb_only_image_mode_wrap img {
  width: 70%
}

.btn_fullWidth {
  width: 100%!important;
  margin: 0px!important
}

.et_pb_bg_layout_dark h2 {
  color: #d7b533!important;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold
}

@media (min-width: 981px) {
  .et_pb_gutters3 .et_pb_column,
  .et_pb_gutters3.et_pb_row .et_pb_column {
      margin-right: 5.5%;
  }
  .et_pb_gutters3 .et_pb_column_4_4,
  .et_pb_gutters3.et_pb_row .et_pb_column_4_4 {
      width: 100%;
  }
  .et_pb_gutters3 .et_pb_column_4_4 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_4_4 .et_pb_module {
      margin-bottom: 2.75%;
  }
  .et_pb_gutters3 .et_pb_column_3_4,
  .et_pb_gutters3.et_pb_row .et_pb_column_3_4 {
      width: 73.625%;
  }
  .et_pb_gutters3 .et_pb_column_3_4 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_3_4 .et_pb_module {
      margin-bottom: 3.735%;
  }
  .et_pb_gutters3 .et_pb_column_2_3,
  .et_pb_gutters3.et_pb_row .et_pb_column_2_3 {
      width: 64.833%;
  }
  .et_pb_gutters3 .et_pb_column_2_3 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_2_3 .et_pb_module {
      margin-bottom: 4.242%;
  }
  .et_pb_gutters3 .et_pb_column_3_5,
  .et_pb_gutters3.et_pb_row .et_pb_column_3_5 {
      width: 57.8%;
  }
  .et_pb_gutters3 .et_pb_column_3_5 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_3_5 .et_pb_module {
      margin-bottom: 4.758%;
  }
  .et_pb_gutters3 .et_pb_column_1_2,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_2 {
      width: 47.25%;
  }
  .et_pb_gutters3 .et_pb_column_1_2 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_2 .et_pb_module {
      margin-bottom: 5.82%;
  }
  .et_pb_gutters3 .et_pb_column_2_5,
  .et_pb_gutters3.et_pb_row .et_pb_column_2_5 {
      width: 36.7%;
  }
  .et_pb_gutters3 .et_pb_column_2_5 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_2_5 .et_pb_module {
      margin-bottom: 7.493%;
  }
  .et_pb_gutters3 .et_pb_column_1_3,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_3 {
      width: 29.6667%;
  }
  .et_pb_gutters3 .et_pb_column_1_3 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_3 .et_pb_module {
      margin-bottom: 9.27%;
  }
  .et_pb_gutters3 .et_pb_column_1_4,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_4 {
      width: 20.875%;
  }
  .et_pb_gutters3 .et_pb_column_1_4 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_4 .et_pb_module {
      margin-bottom: 13.174%;
  }
  .et_pb_gutters3 .et_pb_column_1_5,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_5 {
      width: 15.6%;
  }
  .et_pb_gutters3 .et_pb_column_1_5 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_5 .et_pb_module {
      margin-bottom: 17.628%;
  }
  .et_pb_gutters3 .et_pb_column_1_6,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_6 {
      width: 12.0833%;
  }
  .et_pb_gutters3 .et_pb_column_1_6 .et_pb_module,
  .et_pb_gutters3.et_pb_row .et_pb_column_1_6 .et_pb_module {
      margin-bottom: 22.759%;
  }
  .et_pb_gutters3 .et_full_width_page.woocommerce-page ul.products li.product {
      width: 20.875%;
      margin-right: 5.5%;
      margin-bottom: 5.5%;
  }
  .et_pb_gutters3.et_left_sidebar.woocommerce-page #main-content ul.products li.product,
  .et_pb_gutters3.et_right_sidebar.woocommerce-page #main-content ul.products li.product {
      width: 28.353%;
      margin-right: 7.47%;
  }
  .et_pb_gutters3.et_left_sidebar.woocommerce-page #main-content ul.products.columns-1 li.product,
  .et_pb_gutters3.et_right_sidebar.woocommerce-page #main-content ul.products.columns-1 li.product {
      width: 100%;
      margin-right: 0;
  }
  .et_pb_gutters3.et_left_sidebar.woocommerce-page #main-content ul.products.columns-2 li.product,
  .et_pb_gutters3.et_right_sidebar.woocommerce-page #main-content ul.products.columns-2 li.product {
      width: 48%;
      margin-right: 4%;
  }
  .et_pb_gutters3.et_left_sidebar.woocommerce-page #main-content ul.products.columns-2 li:nth-child(2n + 2),
  .et_pb_gutters3.et_right_sidebar.woocommerce-page #main-content ul.products.columns-2 li:nth-child(2n + 2) {
      margin-right: 0;
  }
  .et_pb_gutters3.et_left_sidebar.woocommerce-page #main-content ul.products.columns-2 li:nth-child(3n + 1),
  .et_pb_gutters3.et_right_sidebar.woocommerce-page #main-content ul.products.columns-2 li:nth-child(3n + 1) {
      clear: none;
  }
}
@media (min-width: 981px) {
  .et_pb_gutter.et_pb_gutters1 #left-area {
      width: 75%;
  }
  .et_pb_gutter.et_pb_gutters1 #sidebar {
      width: 25%;
  }
  .et_pb_gutters1.et_right_sidebar #left-area {
      padding-right: 0;
  }
  .et_pb_gutters1.et_left_sidebar #left-area {
      padding-left: 0;
  }
  .et_pb_gutter.et_pb_gutters1.et_right_sidebar #main-content .container:before {
      right: 25% !important;
  }
  .et_pb_gutter.et_pb_gutters1.et_left_sidebar #main-content .container:before {
      left: 25% !important;
  }
  .et_pb_gutters1 .et_pb_column,
  .et_pb_gutters1.et_pb_row .et_pb_column {
      margin-right: 0;
  }
  .et_pb_gutters1 .et_pb_column_4_4,
  .et_pb_gutters1.et_pb_row .et_pb_column_4_4 {
      width: 100%;
  }
  .et_pb_gutters1 .et_pb_column_4_4 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_4_4 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_3_4,
  .et_pb_gutters1.et_pb_row .et_pb_column_3_4 {
      width: 75%;
  }
  .et_pb_gutters1 .et_pb_column_3_4 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_3_4 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_2_3,
  .et_pb_gutters1.et_pb_row .et_pb_column_2_3 {
      width: 66.667%;
  }
  .et_pb_gutters1 .et_pb_column_2_3 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_2_3 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_3_5,
  .et_pb_gutters1.et_pb_row .et_pb_column_3_5 {
      width: 60%;
  }
  .et_pb_gutters1 .et_pb_column_3_5 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_3_5 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_1_2,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_2 {
      width: 50%;
  }
  .et_pb_gutters1 .et_pb_column_1_2 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_2 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_2_5,
  .et_pb_gutters1.et_pb_row .et_pb_column_2_5 {
      width: 40%;
  }
  .et_pb_gutters1 .et_pb_column_2_5 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_2_5 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_1_3,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_3 {
      width: 33.3333%;
  }
  .et_pb_gutters1 .et_pb_column_1_3 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_3 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_1_4,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_4 {
      width: 25%;
  }
  .et_pb_gutters1 .et_pb_column_1_4 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_4 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_1_5,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_5 {
      width: 20%;
  }
  .et_pb_gutters1 .et_pb_column_1_5 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_5 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_6 {
      width: 16.6667%;
  }
  .et_pb_gutters1 .et_pb_column_1_6 .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column_1_6 .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_full_width_page.woocommerce-page ul.products li.product {
      width: 25%;
      margin-right: 0;
      margin-bottom: 0;
  }
  .et_pb_gutters1.et_left_sidebar.woocommerce-page #main-content ul.products li.product,
  .et_pb_gutters1.et_right_sidebar.woocommerce-page #main-content ul.products li.product {
      width: 33.333%;
      margin-right: 0;
  }
}
@media (max-width: 980px) {
  .et_pb_gutters1 .et_pb_column,
  .et_pb_gutters1 .et_pb_column .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column,
  .et_pb_gutters1.et_pb_row .et_pb_column .et_pb_module {
      margin-bottom: 0;
  }
  .et_pb_gutters1 .et_pb_row_1-2_1-4_1-4 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1 .et_pb_row_1-4_1-4 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1 .et_pb_row_1-4_1-4_1-2 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1 .et_pb_row_1-5_1-5_3-5 > .et_pb_column.et_pb_column_1_5,
  .et_pb_gutters1 .et_pb_row_3-5_1-5_1-5 > .et_pb_column.et_pb_column_1_5,
  .et_pb_gutters1 .et_pb_row_4col > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1 .et_pb_row_5col > .et_pb_column.et_pb_column_1_5,
  .et_pb_gutters1.et_pb_row_1-2_1-4_1-4 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1.et_pb_row_1-4_1-4 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1.et_pb_row_1-4_1-4_1-2 > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1.et_pb_row_1-5_1-5_3-5 > .et_pb_column.et_pb_column_1_5,
  .et_pb_gutters1.et_pb_row_3-5_1-5_1-5 > .et_pb_column.et_pb_column_1_5,
  .et_pb_gutters1.et_pb_row_4col > .et_pb_column.et_pb_column_1_4,
  .et_pb_gutters1.et_pb_row_5col > .et_pb_column.et_pb_column_1_5 {
      width: 50%;
      margin-right: 0;
  }
  .et_pb_gutters1 .et_pb_row_1-2_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1 .et_pb_row_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1 .et_pb_row_1-6_1-6_1-6_1-2 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1 .et_pb_row_6col > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row_1-2_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row_1-6_1-6_1-6_1-2 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row_6col > .et_pb_column.et_pb_column_1_6 {
      width: 33.333%;
      margin-right: 0;
  }
  .et_pb_gutters1 .et_pb_row_1-6_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6,
  .et_pb_gutters1.et_pb_row_1-6_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6 {
      width: 50%;
      margin-right: 0;
  }
}
@media (max-width: 767px) {
  .et_pb_gutters1 .et_pb_column,
  .et_pb_gutters1 .et_pb_column .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column,
  .et_pb_gutters1.et_pb_row .et_pb_column .et_pb_module {
      margin-bottom: 0;
  }
}
@media (max-width: 479px) {
  .et_pb_gutters1 .et_pb_column,
  .et_pb_gutters1.et_pb_row .et_pb_column {
      margin: 0 !important;
  }
  .et_pb_gutters1 .et_pb_column .et_pb_module,
  .et_pb_gutters1.et_pb_row .et_pb_column .et_pb_module {
      margin-bottom: 0;
  }
}
.et_animated {
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both !important;
  animation-fill-mode: both !important;
}
.et_animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.et_had_animation {
  position: relative;
}
@-webkit-keyframes et_pb_fade {
  to {
      opacity: 1;
  }
}
@keyframes et_pb_fade {
  to {
      opacity: 1;
  }
}
.et_animated.fade {
  -webkit-animation-name: et_pb_fade;
  animation-name: et_pb_fade;
}
@-webkit-keyframes et_pb_fadeTop {
  0% {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
@keyframes et_pb_fadeTop {
  0% {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
.et_animated.fadeTop {
  -webkit-animation-name: et_pb_fadeTop;
  animation-name: et_pb_fadeTop;
}
@-webkit-keyframes et_pb_fadeRight {
  0% {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
@keyframes et_pb_fadeRight {
  0% {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
.et_animated.fadeRight {
  -webkit-animation-name: et_pb_fadeRight;
  animation-name: et_pb_fadeRight;
}
@-webkit-keyframes et_pb_fadeBottom {
  0% {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
@keyframes et_pb_fadeBottom {
  0% {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
.et_animated.fadeBottom {
  -webkit-animation-name: et_pb_fadeBottom;
  animation-name: et_pb_fadeBottom;
}
@-webkit-keyframes et_pb_fadeLeft {
  0% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
@keyframes et_pb_fadeLeft {
  0% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}
.et_animated.fadeLeft {
  -webkit-animation-name: et_pb_fadeLeft;
  animation-name: et_pb_fadeLeft;
}
@-webkit-keyframes et_pb_slide {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_slide {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.slide {
  -webkit-animation-name: et_pb_slide;
  animation-name: et_pb_slide;
}
@-webkit-keyframes et_pb_slideTop {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
@keyframes et_pb_slideTop {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
.et_animated.slideTop {
  -webkit-animation-name: et_pb_slideTop;
  animation-name: et_pb_slideTop;
}
@-webkit-keyframes et_pb_slideRight {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
@keyframes et_pb_slideRight {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
.et_animated.slideRight {
  -webkit-animation-name: et_pb_slideRight;
  animation-name: et_pb_slideRight;
}
@-webkit-keyframes et_pb_slideBottom {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
@keyframes et_pb_slideBottom {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
.et_animated.slideBottom {
  -webkit-animation-name: et_pb_slideBottom;
  animation-name: et_pb_slideBottom;
}
@-webkit-keyframes et_pb_slideLeft {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
@keyframes et_pb_slideLeft {
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
.et_animated.slideLeft {
  -webkit-animation-name: et_pb_slideLeft;
  animation-name: et_pb_slideLeft;
}
@-webkit-keyframes et_pb_bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
  }
}
@keyframes et_pb_bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
  }
}
.et_animated.bounce {
  -webkit-animation-name: et_pb_bounce;
  animation-name: et_pb_bounce;
}
@-webkit-keyframes et_pb_bounceTop {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(0, -200px, 0);
      transform: translate3d(0, -200px, 0);
  }
  60% {
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
  }
  75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
  }
  90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_bounceTop {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(0, -200px, 0);
      transform: translate3d(0, -200px, 0);
  }
  60% {
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
  }
  75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
  }
  90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.bounceTop {
  -webkit-animation-name: et_pb_bounceTop;
  animation-name: et_pb_bounceTop;
}
@-webkit-keyframes et_pb_bounceRight {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(200px, 0, 0);
      transform: translate3d(200px, 0, 0);
  }
  60% {
      -webkit-transform: translate3d(-25px, 0, 0);
      transform: translate3d(-25px, 0, 0);
  }
  75% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
  }
  90% {
      -webkit-transform: translate3d(-5px, 0, 0);
      transform: translate3d(-5px, 0, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_bounceRight {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(200px, 0, 0);
      transform: translate3d(200px, 0, 0);
  }
  60% {
      -webkit-transform: translate3d(-25px, 0, 0);
      transform: translate3d(-25px, 0, 0);
  }
  75% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
  }
  90% {
      -webkit-transform: translate3d(-5px, 0, 0);
      transform: translate3d(-5px, 0, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.bounceRight {
  -webkit-animation-name: et_pb_bounceRight;
  animation-name: et_pb_bounceRight;
}
@-webkit-keyframes et_pb_bounceBottom {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(0, 200px, 0);
      transform: translate3d(0, 200px, 0);
  }
  60% {
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
  }
  75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
  }
  90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
  }
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
@keyframes et_pb_bounceBottom {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(0, 200px, 0);
      transform: translate3d(0, 200px, 0);
  }
  60% {
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
  }
  75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
  }
  90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
  }
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
  }
}
.et_animated.bounceBottom {
  -webkit-animation-name: et_pb_bounceBottom;
  animation-name: et_pb_bounceBottom;
}
@-webkit-keyframes et_pb_bounceLeft {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(-200px, 0, 0);
      transform: translate3d(-200px, 0, 0);
  }
  60% {
      -webkit-transform: translate3d(25px, 0, 0);
      transform: translate3d(25px, 0, 0);
  }
  75% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
  }
  90% {
      -webkit-transform: translate3d(5px, 0, 0);
      transform: translate3d(5px, 0, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_bounceLeft {
  0%,
  60%,
  75%,
  90%,
  to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
      -webkit-transform: translate3d(-200px, 0, 0);
      transform: translate3d(-200px, 0, 0);
  }
  60% {
      -webkit-transform: translate3d(25px, 0, 0);
      transform: translate3d(25px, 0, 0);
  }
  75% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
  }
  90% {
      -webkit-transform: translate3d(5px, 0, 0);
      transform: translate3d(5px, 0, 0);
  }
  to {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.bounceLeft {
  -webkit-animation-name: et_pb_bounceLeft;
  animation-name: et_pb_bounceLeft;
}
@-webkit-keyframes et_pb_zoom {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_zoom {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.zoom {
  -webkit-animation-name: et_pb_zoom;
  animation-name: et_pb_zoom;
}
@-webkit-keyframes et_pb_zoomTop {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_zoomTop {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.zoomTop {
  -webkit-animation-name: et_pb_zoomTop;
  animation-name: et_pb_zoomTop;
  -webkit-transform-origin: top;
  transform-origin: top;
}
@-webkit-keyframes et_pb_zoomRight {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_zoomRight {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.zoomRight {
  -webkit-animation-name: et_pb_zoomRight;
  animation-name: et_pb_zoomRight;
  -webkit-transform-origin: right;
  transform-origin: right;
}
@-webkit-keyframes et_pb_zoomBottom {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_zoomBottom {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.zoomBottom {
  -webkit-animation-name: et_pb_zoomBottom;
  animation-name: et_pb_zoomBottom;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
@-webkit-keyframes et_pb_zoomLeft {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
@keyframes et_pb_zoomLeft {
  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
  }
}
.et_animated.zoomLeft {
  -webkit-animation-name: et_pb_zoomLeft;
  animation-name: et_pb_zoomLeft;
  -webkit-transform-origin: left;
  transform-origin: left;
}
@-webkit-keyframes et_pb_flip {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
@keyframes et_pb_flip {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
.et_animated.flip {
  -webkit-animation-name: et_pb_flip;
  animation-name: et_pb_flip;
}
@-webkit-keyframes et_pb_flipTop {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
@keyframes et_pb_flipTop {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
.et_animated.flipTop {
  -webkit-animation-name: et_pb_flipTop;
  animation-name: et_pb_flipTop;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes et_pb_flipRight {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
@keyframes et_pb_flipRight {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
.et_animated.flipRight {
  -webkit-animation-name: et_pb_flipRight;
  animation-name: et_pb_flipRight;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes et_pb_flipBottom {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
@keyframes et_pb_flipBottom {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
.et_animated.flipBottom {
  -webkit-animation-name: et_pb_flipBottom;
  animation-name: et_pb_flipBottom;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes et_pb_flipLeft {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
@keyframes et_pb_flipLeft {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
.et_animated.flipLeft {
  -webkit-animation-name: et_pb_flipLeft;
  animation-name: et_pb_flipLeft;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes et_pb_fold {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
@keyframes et_pb_fold {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
.et_animated.fold {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: et_pb_fold;
  animation-name: et_pb_fold;
}
@-webkit-keyframes et_pb_foldTop {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
@keyframes et_pb_foldTop {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
.et_animated.foldTop {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-name: et_pb_foldTop;
  animation-name: et_pb_foldTop;
}
@-webkit-keyframes et_pb_foldRight {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
@keyframes et_pb_foldRight {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
.et_animated.foldRight {
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-animation-name: et_pb_foldRight;
  animation-name: et_pb_foldRight;
}
@-webkit-keyframes et_pb_foldBottom {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
@keyframes et_pb_foldBottom {
  to {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
  }
}
.et_animated.foldBottom {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: et_pb_foldBottom;
  animation-name: et_pb_foldBottom;
}
@-webkit-keyframes et_pb_foldLeft {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
@keyframes et_pb_foldLeft {
  to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
  }
}
.et_animated.foldLeft {
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-animation-name: et_pb_foldLeft;
  animation-name: et_pb_foldLeft;
}
@-webkit-keyframes et_pb_roll {
  0% {
      -webkit-transform-origin: center;
      transform-origin: center;
  }
  to {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_roll {
  0% {
      -webkit-transform-origin: center;
      transform-origin: center;
  }
  to {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.roll {
  -webkit-animation-name: et_pb_roll;
  animation-name: et_pb_roll;
}
@-webkit-keyframes et_pb_rollTop {
  0% {
      -webkit-transform-origin: top;
      transform-origin: top;
  }
  to {
      -webkit-transform-origin: top;
      transform-origin: top;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_rollTop {
  0% {
      -webkit-transform-origin: top;
      transform-origin: top;
  }
  to {
      -webkit-transform-origin: top;
      transform-origin: top;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.rollTop {
  -webkit-animation-name: et_pb_rollTop;
  animation-name: et_pb_rollTop;
}
@-webkit-keyframes et_pb_rollRight {
  0% {
      -webkit-transform-origin: right;
      transform-origin: right;
  }
  to {
      -webkit-transform-origin: right;
      transform-origin: right;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_rollRight {
  0% {
      -webkit-transform-origin: right;
      transform-origin: right;
  }
  to {
      -webkit-transform-origin: right;
      transform-origin: right;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.rollRight {
  -webkit-animation-name: et_pb_rollRight;
  animation-name: et_pb_rollRight;
}
@-webkit-keyframes et_pb_rollBottom {
  0% {
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
  }
  to {
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_rollBottom {
  0% {
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
  }
  to {
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.rollBottom {
  -webkit-animation-name: et_pb_rollBottom;
  animation-name: et_pb_rollBottom;
}
@-webkit-keyframes et_pb_rollLeft {
  0% {
      -webkit-transform-origin: left;
      transform-origin: left;
  }
  to {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
@keyframes et_pb_rollLeft {
  0% {
      -webkit-transform-origin: left;
      transform-origin: left;
  }
  to {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
  }
}
.et_animated.rollLeft {
  -webkit-animation-name: et_pb_rollLeft;
  animation-name: et_pb_rollLeft;
}
.et_pb_widget {
  float: left;
  max-width: 100%;
  word-wrap: break-word;
}
.et_pb_widget a {
  text-decoration: none;
  color: #666;
}
.et_pb_widget li a:hover {
  color: #82c0c7;
}
.et_pb_widget ol li,
.et_pb_widget ul li {
  margin-bottom: 0.5em;
}
.et_pb_widget ol li ol li,
.et_pb_widget ul li ul li {
  margin-left: 15px;
}
.et_pb_widget select {
  width: 100%;
  height: 28px;
  padding: 0 5px;
}
.et_pb_widget_area .et_pb_widget a {
  color: inherit;
}
.et_pb_bg_layout_light .et_pb_widget li a {
  color: #666;
}
.et_pb_bg_layout_dark .et_pb_widget li a {
  color: inherit;
}
.widget_search .screen-reader-text,
.et_pb_widget .wp-block-search__label {
  display: none;
}
.widget_search input#s,
.widget_search input#searchsubmit,
.et_pb_widget .wp-block-search__input,
.et_pb_widget .wp-block-search__button {
  padding: 0.7em;
  height: 40px !important;
  margin: 0;
  font-size: 14px;
  line-height: normal !important;
  border: 1px solid #ddd;
  color: #666;
}
.widget_search #s,
.et_pb_widget .wp-block-search__input {
  width: 100%;
  border-radius: 3px;
}
.widget_search #searchform,
.et_pb_widget .wp-block-search {
  position: relative;
}
.widget_search #searchsubmit,
.et_pb_widget .wp-block-search__button {
  background-color: #ddd;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
}
#searchsubmit,
.et_pb_widget .wp-block-search__button {
  cursor: pointer;
}
.et_pb_section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;
}
.et_pb_section--absolute,
.et_pb_section--fixed {
  width: 100%;
}
.et_pb_section.et_section_transparent {
  background-color: transparent;
}
.et_pb_fullwidth_section {
  padding: 0;
}
.et_pb_fullwidth_section > .et_pb_module:not(.et_pb_post_content):not(.et_pb_fullwidth_post_content) .et_pb_row {
  padding: 0 !important;
}
.et_pb_inner_shadow {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.07);
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.07);
}
.et_pb_bottom_inside_divider,
.et_pb_top_inside_divider {
  display: block;
  background-repeat-y: no-repeat;
  height: 100%;
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0;
  right: 0;
}
.et_pb_bottom_inside_divider.et-no-transition,
.et_pb_top_inside_divider.et-no-transition {
  -webkit-transition: none !important;
  transition: none !important;
}
.et-fb .section_has_divider.et_fb_element_controls_visible--child > .et_pb_bottom_inside_divider,
.et-fb .section_has_divider.et_fb_element_controls_visible--child > .et_pb_top_inside_divider {
  z-index: 1;
}
.et_pb_section_video:not(.et_pb_section--with-menu) {
  overflow: hidden;
  position: relative;
}
.et_pb_column > .et_pb_section_video_bg {
  z-index: -1;
}
.et_pb_section_video_bg {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  pointer-events: none;
  -webkit-transition: display 0.3s;
  transition: display 0.3s;
}
.et_pb_section_video_bg.et_pb_section_video_bg_hover,
.et_pb_section_video_bg.et_pb_section_video_bg_phone,
.et_pb_section_video_bg.et_pb_section_video_bg_tablet,
.et_pb_section_video_bg.et_pb_section_video_bg_tablet_only {
  display: none;
}
.et_pb_section_video_bg .mejs-controls,
.et_pb_section_video_bg .mejs-overlay-play {
  display: none !important;
}
.et_pb_section_video_bg embed,
.et_pb_section_video_bg iframe,
.et_pb_section_video_bg object,
.et_pb_section_video_bg video {
  max-width: none;
}
.et_pb_section_video_bg .mejs-video {
  left: 50%;
  position: absolute;
  max-width: none;
}
.et_pb_section_video_bg .mejs-overlay-loading {
  display: none !important;
}
.et_pb_social_network_link .et_pb_section_video {
  overflow: visible;
}
.et_pb_section_video_on_hover:hover > .et_pb_section_video_bg {
  display: none;
}
.et_pb_section_video_on_hover:hover > .et_pb_section_video_bg_hover,
.et_pb_section_video_on_hover:hover > .et_pb_section_video_bg_hover_inherit {
  display: block;
}
@media (min-width: 981px) {
  .et_pb_section {
      padding: 4% 0;
  }
  body.et_pb_pagebuilder_layout.et_pb_show_title .post-password-required .et_pb_section,
  body:not(.et_pb_pagebuilder_layout) .post-password-required .et_pb_section {
      padding-top: 0;
  }
  .et_pb_fullwidth_section {
      padding: 0;
  }
  .et_pb_section_video_bg.et_pb_section_video_bg_desktop_only {
      display: block;
  }
}
@media (max-width: 980px) {
  .et_pb_section {
      padding: 50px 0;
  }
  body.et_pb_pagebuilder_layout.et_pb_show_title .post-password-required .et_pb_section,
  body:not(.et_pb_pagebuilder_layout) .post-password-required .et_pb_section {
      padding-top: 0;
  }
  .et_pb_fullwidth_section {
      padding: 0;
  }
  .et_pb_section_video_bg.et_pb_section_video_bg_tablet {
      display: block;
  }
  .et_pb_section_video_bg.et_pb_section_video_bg_desktop_only {
      display: none;
  }
}
@media (min-width: 768px) {
  .et_pb_section_video_bg.et_pb_section_video_bg_desktop_tablet {
      display: block;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  .et_pb_section_video_bg.et_pb_section_video_bg_tablet_only {
      display: block;
  }
}
@media (max-width: 767px) {
  .et_pb_section_video_bg.et_pb_section_video_bg_phone {
      display: block;
  }
  .et_pb_section_video_bg.et_pb_section_video_bg_desktop_tablet {
      display: none;
  }
}
.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}
body.safari .section_has_divider,
body.uiwebview .section_has_divider {
  -webkit-perspective: 2000px;
  perspective: 2000px;
}
.section_has_divider .et_pb_row {
  z-index: 5;
}
.et_pb_row_inner {
  width: 100%;
  position: relative;
}
.et_pb_row.et_pb_row_empty,
.et_pb_row_inner:nth-of-type(n + 2).et_pb_row_empty {
  display: none;
}
.et_pb_row:after,
.et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}
.et_pb_row_4col .et-last-child,
.et_pb_row_4col .et-last-child-2,
.et_pb_row_6col .et-last-child,
.et_pb_row_6col .et-last-child-2,
.et_pb_row_6col .et-last-child-3 {
  margin-bottom: 0;
}
.et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}
.et_pb_column--with-menu {
  z-index: 3;
}
.et_pb_column.et_pb_column_empty {
  min-height: 1px;
}
.et_pb_row .et_pb_column.et-last-child,
.et_pb_row .et_pb_column:last-child,
.et_pb_row_inner .et_pb_column.et-last-child,
.et_pb_row_inner .et_pb_column:last-child {
  margin-right: 0 !important;
}
.et_pb_column.et_pb_section_parallax {
  position: relative;
}
.et_pb_column,
.et_pb_row,
.et_pb_row_inner {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media (min-width: 981px) {
  .et_pb_row {
      padding: 2% 0;
  }
  body.et_pb_pagebuilder_layout.et_pb_show_title .post-password-required .et_pb_row,
  body:not(.et_pb_pagebuilder_layout) .post-password-required .et_pb_row {
      padding: 0;
      width: 100%;
  }
  .et_pb_column_3_4 .et_pb_row_inner {
      padding: 3.735% 0;
  }
  .et_pb_column_2_3 .et_pb_row_inner {
      padding: 4.2415% 0;
  }
  .et_pb_column_1_2 .et_pb_row_inner,
  .et_pb_column_3_5 .et_pb_row_inner {
      padding: 5.82% 0;
  }
  .et_section_specialty > .et_pb_row {
      padding: 0;
  }
  .et_pb_row_inner {
      width: 100%;
  }
  .et_pb_column_single {
      padding: 2.855% 0;
  }
  .et_pb_column_single .et_pb_module.et-first-child,
  .et_pb_column_single .et_pb_module:first-child {
      margin-top: 0;
  }
  .et_pb_column_single .et_pb_module.et-last-child,
  .et_pb_column_single .et_pb_module:last-child {
      margin-bottom: 0;
  }
  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child,
  .et_pb_row_inner .et_pb_column.et-last-child,
  .et_pb_row_inner .et_pb_column:last-child {
      margin-right: 0 !important;
  }
  .et_pb_row.et_pb_equal_columns,
  .et_pb_row_inner.et_pb_equal_columns,
  .et_pb_section.et_pb_equal_columns > .et_pb_row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
  .rtl .et_pb_row.et_pb_equal_columns,
  .rtl .et_pb_row_inner.et_pb_equal_columns,
  .rtl .et_pb_section.et_pb_equal_columns > .et_pb_row {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  }
  .et_pb_row.et_pb_equal_columns > .et_pb_column,
  .et_pb_section.et_pb_equal_columns > .et_pb_row > .et_pb_column {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
  }
}
@media (max-width: 980px) {
  .et_pb_row {
      max-width: 1080px;
  }
  body.et_pb_pagebuilder_layout.et_pb_show_title .post-password-required .et_pb_row,
  body:not(.et_pb_pagebuilder_layout) .post-password-required .et_pb_row {
      padding: 0;
      width: 100%;
  }
  .et_pb_column .et_pb_row_inner,
  .et_pb_row {
      padding: 30px 0;
  }
  .et_section_specialty > .et_pb_row {
      padding: 0;
  }
  .et_pb_column {
      width: 100%;
      margin-bottom: 30px;
  }
  .et_pb_bottom_divider .et_pb_row:nth-last-child(2) .et_pb_column:last-child,
  .et_pb_row .et_pb_column.et-last-child,
  .et_pb_row .et_pb_column:last-child {
      margin-bottom: 0;
  }
  .et_section_specialty .et_pb_row > .et_pb_column {
      padding-bottom: 0;
  }
  .et_pb_column.et_pb_column_empty {
      display: none;
  }
  .et_pb_row_1-2_1-4_1-4,
  .et_pb_row_1-2_1-6_1-6_1-6,
  .et_pb_row_1-4_1-4,
  .et_pb_row_1-4_1-4_1-2,
  .et_pb_row_1-5_1-5_3-5,
  .et_pb_row_1-6_1-6_1-6,
  .et_pb_row_1-6_1-6_1-6_1-2,
  .et_pb_row_1-6_1-6_1-6_1-6,
  .et_pb_row_3-5_1-5_1-5,
  .et_pb_row_4col,
  .et_pb_row_5col,
  .et_pb_row_6col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  .et_pb_row_1-4_1-4 > .et_pb_column.et_pb_column_1_4,
  .et_pb_row_1-4_1-4_1-2 > .et_pb_column.et_pb_column_1_4,
  .et_pb_row_4col > .et_pb_column.et_pb_column_1_4 {
      width: 47.25%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-4_1-4 > .et_pb_column.et_pb_column_1_4:nth-child(2n),
  .et_pb_row_1-4_1-4_1-2 > .et_pb_column.et_pb_column_1_4:nth-child(2n),
  .et_pb_row_4col > .et_pb_column.et_pb_column_1_4:nth-child(2n) {
      margin-right: 0;
  }
  .et_pb_row_1-2_1-4_1-4 > .et_pb_column.et_pb_column_1_4 {
      width: 47.25%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-2_1-4_1-4 > .et_pb_column.et_pb_column_1_2,
  .et_pb_row_1-2_1-4_1-4 > .et_pb_column.et_pb_column_1_4:nth-child(odd) {
      margin-right: 0;
  }
  .et_pb_row_1-2_1-4_1-4 .et_pb_column:nth-last-child(-n + 2),
  .et_pb_row_1-4_1-4 .et_pb_column:nth-last-child(-n + 2),
  .et_pb_row_4col .et_pb_column:nth-last-child(-n + 2) {
      margin-bottom: 0;
  }
  .et_pb_row_1-5_1-5_3-5 > .et_pb_column.et_pb_column_1_5,
  .et_pb_row_5col > .et_pb_column.et_pb_column_1_5 {
      width: 47.25%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-5_1-5_3-5 > .et_pb_column.et_pb_column_1_5:nth-child(2n),
  .et_pb_row_5col > .et_pb_column.et_pb_column_1_5:nth-child(2n) {
      margin-right: 0;
  }
  .et_pb_row_3-5_1-5_1-5 > .et_pb_column.et_pb_column_1_5 {
      width: 47.25%;
      margin-right: 5.5%;
  }
  .et_pb_row_3-5_1-5_1-5 > .et_pb_column.et_pb_column_1_5:nth-child(odd),
  .et_pb_row_3-5_1-5_1-5 > .et_pb_column.et_pb_column_3_5 {
      margin-right: 0;
  }
  .et_pb_row_3-5_1-5_1-5 .et_pb_column:nth-last-child(-n + 2),
  .et_pb_row_5col .et_pb_column:last-child {
      margin-bottom: 0;
  }
  .et_pb_row_1-6_1-6_1-6_1-2 > .et_pb_column.et_pb_column_1_6,
  .et_pb_row_6col > .et_pb_column.et_pb_column_1_6 {
      width: 29.666%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-6_1-6_1-6_1-2 > .et_pb_column.et_pb_column_1_6:nth-child(3n),
  .et_pb_row_6col > .et_pb_column.et_pb_column_1_6:nth-child(3n) {
      margin-right: 0;
  }
  .et_pb_row_1-2_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6 {
      width: 29.666%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-2_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_2,
  .et_pb_row_1-2_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6:last-child {
      margin-right: 0;
  }
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2,
  .et_pb_row_1-2_1-6_1-6_1-6 .et_pb_column:nth-last-child(-n + 3),
  .et_pb_row_6col .et_pb_column:nth-last-child(-n + 3) {
      margin-bottom: 0;
  }
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2 .et_pb_column.et_pb_column_1_6 {
      width: 29.666%;
      margin-right: 5.5%;
      margin-bottom: 0;
  }
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2 .et_pb_column.et_pb_column_1_6:last-child {
      margin-right: 0;
  }
  .et_pb_row_1-6_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6 {
      width: 47.25%;
      margin-right: 5.5%;
  }
  .et_pb_row_1-6_1-6_1-6_1-6 > .et_pb_column.et_pb_column_1_6:nth-child(2n) {
      margin-right: 0;
  }
  .et_pb_row_1-6_1-6_1-6_1-6:nth-last-child(-n + 3) {
      margin-bottom: 0;
  }
}
@media (max-width: 479px) {
  .et_pb_row .et_pb_column.et_pb_column_1_4,
  .et_pb_row .et_pb_column.et_pb_column_1_5,
  .et_pb_row .et_pb_column.et_pb_column_1_6 {
      width: 100%;
      margin: 0 0 30px;
  }
  .et_pb_row .et_pb_column.et_pb_column_1_4.et-last-child,
  .et_pb_row .et_pb_column.et_pb_column_1_4:last-child,
  .et_pb_row .et_pb_column.et_pb_column_1_5.et-last-child,
  .et_pb_row .et_pb_column.et_pb_column_1_5:last-child,
  .et_pb_row .et_pb_column.et_pb_column_1_6.et-last-child,
  .et_pb_row .et_pb_column.et_pb_column_1_6:last-child {
      margin-bottom: 0;
  }
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2 .et_pb_column.et_pb_column_1_6 {
      width: 100%;
      margin: 0 0 30px;
  }
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2 .et_pb_column.et_pb_column_1_6.et-last-child,
  .et_pb_row_1-2_1-2 .et_pb_column.et_pb_column_1_2 .et_pb_column.et_pb_column_1_6:last-child {
      margin-bottom: 0;
  }
  .et_pb_column {
      width: 100% !important;
  }
}
.et_pb_code_inner {
  position: relative;
}
.et_pb_slider .et_pb_container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.et_pb_with_border .et_pb_slide_image img {
  border: 0 solid #333;
}
.et_pb_slides .et_pb_container {
  display: table;
  table-layout: fixed;
}
.et_pb_slides .et_pb_slider_container_inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.et_pb_slides .et_pb_empty_slide.et_pb_container,
.et_pb_slides .et_pb_empty_slide .et_pb_slider_container_inner {
  display: block;
}
.et_pb_slide_content {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.et_pb_slide_content p:last-of-type {
  padding-bottom: 0;
}
.et_pb_bg_layout_dark .et_pb_slide_content {
  color: #fff;
}
.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
  padding: 30px;
  border-radius: 3px;
}
.et_pb_slide_description {
  word-wrap: break-word;
}
.et-pb-active-slide .et_pb_slide_description {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeBottom;
  animation-name: fadeBottom;
}
.et_pb_bg_layout_dark .et_pb_slide_description {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.et_pb_slide_description,
.et_pb_slider_fullwidth_off .et_pb_slide_description {
  padding: 16% 8%;
  width: auto;
  margin: auto;
}
.et_pb_slide_with_image .et_pb_slide_description {
  width: 47.25%;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  float: right;
}
.et_pb_slide_description .et_pb_slide_title {
  font-weight: 300;
  margin-top: 0;
  font-size: 46px;
}
.et_pb_slide_image {
  position: absolute;
  bottom: 0;
}
.et_pb_slide_video {
  position: absolute;
  top: 50%;
  bottom: auto;
}
.et_pb_slide:first-child .et_pb_slide_image img {
  opacity: 0;
}
.et_pb_slide:first-child .et_pb_slide_image img.active {
  opacity: 1;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
}
.et_pb_media_alignment_center .et_pb_slide_image {
  bottom: auto;
  top: 50%;
}
.et-pb-active-slide .et_pb_slide_image,
.et-pb-active-slide .et_pb_slide_video {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.et-pb-active-slide .et_pb_slide_image {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
}
.et_pb_slide_image img {
  width: auto;
  vertical-align: bottom;
}
.et_pb_slide_image,
.et_pb_slide_video {
  width: 47.25%;
  margin-right: 5.5%;
}
.et_pb_slide_video .mejs-mediaelement {
  position: relative;
}
.et_pb_slide_video .mejs-mediaelement .wp-video-shortcode {
  display: block;
}
.et_pb_slide_video .wp-video-shortcode video,
.et_pb_slide_video video.wp-video-shortcode {
  height: auto;
}
.et_pb_slider_with_overlay .et_pb_slide_overlay_container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.et_pb_bg_layout_light.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
.et_pb_bg_layout_light.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
  background-color: hsla(0, 0%, 100%, 0.9);
}
.et_pb_bg_layout_dark.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
.et_pb_bg_layout_dark.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
  background-color: rgba(0, 0, 0, 0.3);
}
.et_pb_column_1_2 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_1_3 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_2_5 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_3_5 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_3_8 .et_pb_slider_fullwidth_off .et_pb_slide_description {
  text-align: center;
}
.et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_content,
.et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_content,
.et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_content,
.et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_video {
  display: none;
}
.et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_description,
.et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_description,
.et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_description,
.et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_description {
  text-align: center;
}
.et_pb_column_1_2 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_1_2 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_1_3 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_1_3 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_1_3 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_content,
.et_pb_column_2_5 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_2_5 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_2_5 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_content,
.et_pb_column_3_5 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_3_5 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_3_8 .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column_3_8 .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column_3_8 .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_content {
  display: none;
}
.et_pb_column_1_2 .et_pb_slide_content,
.et_pb_column_1_3 .et_pb_slide_content,
.et_pb_column_1_4 .et_pb_slide_content,
.et_pb_column_1_5 .et_pb_slide_content,
.et_pb_column_1_6 .et_pb_slide_content,
.et_pb_column_2_5 .et_pb_slide_content,
.et_pb_column_3_4 .et_pb_column_3_8 .et_pb_slide_content,
.et_pb_column_3_5 .et_pb_slide_content {
  font-size: 14px;
}
.et_pb_column .et_pb_slider_fullwidth_off .et_pb_slide_content,
.et_pb_column .et_pb_slider_fullwidth_off .et_pb_slide_image,
.et_pb_column .et_pb_slider_fullwidth_off .et_pb_slide_video,
.et_pb_column .et_pb_slider_fullwidth_off .et_pb_slide_with_image .et_pb_slide_content {
  display: block;
}
.et_pb_column_1_2 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_3_4 .et_pb_column_3_8 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_3_5 .et_pb_slide_description h2.et_pb_slide_title {
  font-size: 26px;
  font-weight: 400;
}
.et_pb_column_1_3 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_1_4 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_1_5 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_1_6 .et_pb_slide_description h2.et_pb_slide_title,
.et_pb_column_2_5 .et_pb_slide_description h2.et_pb_slide_title {
  font-size: 22px;
  font-weight: 400;
}
@media (min-width: 981px) and (max-width: 1100px) {
  .et_pb_column_1_2 .et_pb_slide_content,
  .et_pb_column_1_3 .et_pb_slide_content,
  .et_pb_column_1_4 .et_pb_slide_content,
  .et_pb_column_1_5 .et_pb_slide_content,
  .et_pb_column_1_6 .et_pb_slide_content,
  .et_pb_column_2_3 .et_pb_slide_content,
  .et_pb_column_2_5 .et_pb_slide_content,
  .et_pb_column_3_5 .et_pb_slide_content {
      font-size: 14px;
  }
  .et_pb_column_1_2 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_1_3 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_1_4 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_1_5 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_1_6 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_2_3 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_2_5 .et_pb_slide_description h2.et_pb_slide_title,
  .et_pb_column_3_5 .et_pb_slide_description h2.et_pb_slide_title {
      font-size: 18px;
  }
}
@media (min-width: 981px) {
  .et_pb_column_1_2 .et_pb_slide_image,
  .et_pb_column_1_2 .et_pb_slide_video,
  .et_pb_column_1_3 .et_pb_slide_image,
  .et_pb_column_1_3 .et_pb_slide_video,
  .et_pb_column_1_4 .et_pb_slide_image,
  .et_pb_column_1_4 .et_pb_slide_video,
  .et_pb_column_1_5 .et_pb_slide_image,
  .et_pb_column_1_5 .et_pb_slide_video,
  .et_pb_column_1_6 .et_pb_slide_image,
  .et_pb_column_1_6 .et_pb_slide_video,
  .et_pb_column_2_5 .et_pb_slide_image,
  .et_pb_column_2_5 .et_pb_slide_video,
  .et_pb_column_3_5 .et_pb_slide_image,
  .et_pb_column_3_5 .et_pb_slide_video,
  .et_pb_column_3_8 .et_pb_slide_image,
  .et_pb_column_3_8 .et_pb_slide_video {
      top: auto;
      bottom: auto;
      position: relative;
  }
  .et_pb_column_1_2 .et_pb_slide_image,
  .et_pb_column_1_2 .et_pb_slide_video,
  .et_pb_column_1_2 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_1_3 .et_pb_slide_image,
  .et_pb_column_1_3 .et_pb_slide_video,
  .et_pb_column_1_3 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_1_4 .et_pb_slide_image,
  .et_pb_column_1_4 .et_pb_slide_video,
  .et_pb_column_1_4 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_1_5 .et_pb_slide_image,
  .et_pb_column_1_5 .et_pb_slide_video,
  .et_pb_column_1_5 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_1_6 .et_pb_slide_image,
  .et_pb_column_1_6 .et_pb_slide_video,
  .et_pb_column_1_6 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_2_5 .et_pb_slide_image,
  .et_pb_column_2_5 .et_pb_slide_video,
  .et_pb_column_2_5 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_3_5 .et_pb_slide_image,
  .et_pb_column_3_5 .et_pb_slide_video,
  .et_pb_column_3_5 .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_column_3_8 .et_pb_slide_image,
  .et_pb_column_3_8 .et_pb_slide_video,
  .et_pb_column_3_8 .et_pb_slide_with_image .et_pb_slide_description {
      width: 100% !important;
  }
  .et_pb_column_1_2 .et_pb_slide_image,
  .et_pb_column_1_2 .et_pb_slide_video,
  .et_pb_column_1_3 .et_pb_slide_image,
  .et_pb_column_1_3 .et_pb_slide_video,
  .et_pb_column_1_4 .et_pb_slide_image,
  .et_pb_column_1_4 .et_pb_slide_video,
  .et_pb_column_1_5 .et_pb_slide_image,
  .et_pb_column_1_5 .et_pb_slide_video,
  .et_pb_column_1_6 .et_pb_slide_image,
  .et_pb_column_1_6 .et_pb_slide_video,
  .et_pb_column_2_5 .et_pb_slide_image,
  .et_pb_column_2_5 .et_pb_slide_video,
  .et_pb_column_3_5 .et_pb_slide_image,
  .et_pb_column_3_5 .et_pb_slide_video,
  .et_pb_column_3_8 .et_pb_slide_image,
  .et_pb_column_3_8 .et_pb_slide_video {
      padding: 0;
      margin: 10% 0 0 !important;
  }
  .et_pb_column_1_3 .et_pb_slide_description,
  .et_pb_column_2_5 .et_pb_slide_description {
      padding-bottom: 26%;
  }
  .et_pb_column_1_4 .et_pb_slide .et_pb_slide_description,
  .et_pb_column_1_5 .et_pb_slide .et_pb_slide_description,
  .et_pb_column_1_6 .et_pb_slide .et_pb_slide_description {
      padding-bottom: 34%;
  }
}
@media (max-width: 980px) {
  .et_pb_column_1_4 .et_pb_slider_fullwidth_off h2,
  .et_pb_column_1_5 .et_pb_slider_fullwidth_off h2,
  .et_pb_column_1_6 .et_pb_slider_fullwidth_off h2,
  .et_pb_slide_description h2.et_pb_slide_title {
      font-size: 26px;
      font-weight: 500;
  }
  .et_pb_slide_description .et_pb_slide_title {
      font-weight: 500;
  }
  .et_pb_slide_content {
      font-size: 13px;
      font-weight: 400;
  }
  .et_pb_slide_description {
      text-align: center;
  }
  .et_pb_slide_with_image .et_pb_slide_description {
      text-align: left;
  }
  .et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_container,
  .et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_container,
  .et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_container {
      min-height: auto;
  }
  .et_pb_column_1_4 .et_pb_slider_fullwidth_off .et_pb_slide_content,
  .et_pb_column_1_5 .et_pb_slider_fullwidth_off .et_pb_slide_content,
  .et_pb_column_1_6 .et_pb_slider_fullwidth_off .et_pb_slide_content {
      display: block;
  }
  .et_pb_bg_layout_light_tablet.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
  .et_pb_bg_layout_light_tablet.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
      background-color: hsla(0, 0%, 100%, 0.9);
  }
  .et_pb_bg_layout_dark_tablet.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
  .et_pb_bg_layout_dark_tablet.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
      background-color: rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 767px) {
  .et_pb_slide_content {
      font-size: 12px;
  }
  .et_pb_slider.et_pb_module .et_pb_slides .et_pb_slide_content {
      font-size: 14px;
  }
  .et_pb_slide_description h2.et_pb_slide_title {
      font-size: 24px;
  }
  .et_pb_slider.et_pb_module .et_pb_slides .et_pb_slide_description h2.et_pb_slide_title {
      font-size: 20px;
  }
  .et_pb_slide_description,
  .et_pb_slide_with_image .et_pb_slide_description,
  .et_pb_slider_fullwidth_off .et_pb_slide_description {
      text-align: center;
  }
  .et_pb_slide_with_image .et_pb_slide_description {
      float: none;
      text-align: center;
      width: 100%;
  }
  .et_pb_media_alignment_center .et_pb_slide_image {
      top: 0;
  }
  .et_pb_slide_image,
  .et_pb_slide_video,
  .et_pb_slide_with_image .et_pb_slide_description {
      width: 100%;
  }
  .et_pb_slider_show_image .et_pb_slide_image,
  .et_pb_slider_show_image .et_pb_slide_video {
      display: block !important;
  }
  .et_pb_slide_image,
  .et_pb_slide_video {
      display: none !important;
      position: relative;
      top: auto;
      margin: 50px auto 0;
      padding: 0;
      margin-top: 6% !important;
  }
  .et_pb_slide_video {
      float: none;
  }
  .et_pb_slide_image img {
      max-height: 300px;
  }
  .et_pb_section_first .et_pb_slide_image img {
      max-height: 300px !important;
  }
  .et_pb_section_first .et_pb_slide_image {
      margin: 0 !important;
      top: 20px;
  }
  .et_pb_slider_with_overlay .et_pb_slide_image,
  .et_pb_slider_with_overlay .et_pb_slide_video,
  .et_pb_slider_with_overlay .et_pb_slide_with_image .et_pb_slide_description {
      width: 100%;
  }
  .et_pb_slider_with_overlay .et_pb_slide_image {
      margin-top: 0 !important;
      padding-top: 6%;
  }
  .et_pb_bg_layout_light_phone .et_pb_slide_content {
      color: #333;
  }
  .et_pb_bg_layout_dark_phone .et_pb_slide_description {
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
  .et_pb_bg_layout_dark_phone .et_pb_slide_content {
      color: #fff;
  }
}
@media (min-width: 480px) {
  .et_pb_column_1_4 .et_pb_slide_description,
  .et_pb_column_1_5 .et_pb_slide_description,
  .et_pb_column_1_6 .et_pb_slide_description {
      padding-bottom: 26%;
  }
}
@media (max-width: 479px) {
  .et_pb_slide_description h2.et_pb_slide_title {
      font-size: 20px;
  }
  .et_pb_slide_content {
      font-weight: 400;
      font-size: 10px;
      display: block;
  }
  .et_pb_slider_fullwidth_off .et_pb_more_button,
  .et_pb_slider_fullwidth_off .et_pb_slide_content {
      display: none;
  }
}
.et_pb_slider {
  position: relative;
  overflow: hidden;
}
.et_pb_slide {
  padding: 0 6%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.et_pb_slider .et_pb_slide {
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  width: 100%;
  text-align: center;
  list-style: none !important;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;
}
.et_pb_slider .et_pb_slide:first-child {
  display: list-item;
}
.et-pb-controllers {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.et-pb-controllers a {
  display: inline-block;
  background-color: hsla(0, 0%, 100%, 0.5);
  text-indent: -9999px;
  border-radius: 7px;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  padding: 0;
  opacity: 0.5;
}
.et-pb-controllers .et-pb-active-control {
  opacity: 1;
}
.et-pb-controllers a:last-child {
  margin-right: 0;
}
.et-pb-controllers .et-pb-active-control {
  background-color: #fff;
}
.et_pb_slides .et_pb_temp_slide {
  display: block;
}
.et_pb_slides:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}
@media (max-width: 980px) {
  .et_pb_bg_layout_light_tablet .et-pb-controllers .et-pb-active-control {
      background-color: #333;
  }
  .et_pb_bg_layout_light_tablet .et-pb-controllers a {
      background-color: rgba(0, 0, 0, 0.3);
  }
  .et_pb_bg_layout_light_tablet .et_pb_slide_content {
      color: #333;
  }
  .et_pb_bg_layout_dark_tablet .et_pb_slide_description {
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
  .et_pb_bg_layout_dark_tablet .et_pb_slide_content {
      color: #fff;
  }
  .et_pb_bg_layout_dark_tablet .et-pb-controllers .et-pb-active-control {
      background-color: #fff;
  }
  .et_pb_bg_layout_dark_tablet .et-pb-controllers a {
      background-color: hsla(0, 0%, 100%, 0.5);
  }
}
@media (max-width: 767px) {
  .et-pb-controllers {
      position: absolute;
      bottom: 5%;
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 10;
      height: 14px;
  }
  .et_transparent_nav .et_pb_section:first-child .et-pb-controllers {
      bottom: 18px;
  }
  .et_pb_bg_layout_light_phone.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
  .et_pb_bg_layout_light_phone.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper {
      background-color: hsla(0, 0%, 100%, 0.9);
  }
  .et_pb_bg_layout_light_phone .et-pb-controllers .et-pb-active-control {
      background-color: #333;
  }
  .et_pb_bg_layout_dark_phone.et_pb_slider_with_overlay .et_pb_slide_overlay_container,
  .et_pb_bg_layout_dark_phone.et_pb_slider_with_text_overlay .et_pb_text_overlay_wrapper,
  .et_pb_bg_layout_light_phone .et-pb-controllers a {
      background-color: rgba(0, 0, 0, 0.3);
  }
  .et_pb_bg_layout_dark_phone .et-pb-controllers .et-pb-active-control {
      background-color: #fff;
  }
  .et_pb_bg_layout_dark_phone .et-pb-controllers a {
      background-color: hsla(0, 0%, 100%, 0.5);
  }
}
.et_mobile_device .et_pb_slider_parallax .et_pb_slide,
.et_mobile_device .et_pb_slides .et_parallax_bg.et_pb_parallax_css {
  background-attachment: scroll;
}
.et-pb-arrow-next,
.et-pb-arrow-prev {
  position: absolute;
  top: 50%;
  z-index: 100;
  font-size: 48px;
  color: #fff;
  margin-top: -24px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.et_pb_bg_layout_light .et-pb-arrow-next,
.et_pb_bg_layout_light .et-pb-arrow-prev {
  color: #333;
}
.et_pb_slider:hover .et-pb-arrow-prev {
  left: 22px;
  opacity: 1;
}
.et_pb_slider:hover .et-pb-arrow-next {
  right: 22px;
  opacity: 1;
}
.et_pb_bg_layout_light .et-pb-controllers .et-pb-active-control {
  background-color: #333;
}
.et_pb_bg_layout_light .et-pb-controllers a {
  background-color: rgba(0, 0, 0, 0.3);
}
.et-pb-arrow-next:hover,
.et-pb-arrow-prev:hover {
  text-decoration: none;
}
.et-pb-arrow-next span,
.et-pb-arrow-prev span {
  display: none;
}
.et-pb-arrow-prev {
  left: -22px;
}
.et-pb-arrow-next {
  right: -22px;
}
.et-pb-arrow-prev:before {
  content: "4";
}
.et-pb-arrow-next:before {
  content: "5";
}
.format-gallery .et-pb-arrow-next,
.format-gallery .et-pb-arrow-prev {
  color: #fff;
}
.et_pb_column_1_3 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_1_4 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_1_5 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_1_6 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_2_5 .et_pb_slider:hover .et-pb-arrow-prev {
  left: 0;
}
.et_pb_column_1_3 .et_pb_slider:hover .et-pb-arrow-next,
.et_pb_column_1_4 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_1_5 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_1_6 .et_pb_slider:hover .et-pb-arrow-prev,
.et_pb_column_2_5 .et_pb_slider:hover .et-pb-arrow-next {
  right: 0;
}
.et_pb_column_1_4 .et_pb_slider .et_pb_slide,
.et_pb_column_1_5 .et_pb_slider .et_pb_slide,
.et_pb_column_1_6 .et_pb_slider .et_pb_slide {
  min-height: 170px;
}
.et_pb_column_1_4 .et_pb_slider:hover .et-pb-arrow-next,
.et_pb_column_1_5 .et_pb_slider:hover .et-pb-arrow-next,
.et_pb_column_1_6 .et_pb_slider:hover .et-pb-arrow-next {
  right: 0;
}
@media (max-width: 980px) {
  .et_pb_bg_layout_light_tablet .et-pb-arrow-next,
  .et_pb_bg_layout_light_tablet .et-pb-arrow-prev {
      color: #333;
  }
  .et_pb_bg_layout_dark_tablet .et-pb-arrow-next,
  .et_pb_bg_layout_dark_tablet .et-pb-arrow-prev {
      color: #fff;
  }
}
@media (max-width: 767px) {
  .et_pb_slider:hover .et-pb-arrow-prev {
      left: 0;
      opacity: 1;
  }
  .et_pb_slider:hover .et-pb-arrow-next {
      right: 0;
      opacity: 1;
  }
  .et_pb_bg_layout_light_phone .et-pb-arrow-next,
  .et_pb_bg_layout_light_phone .et-pb-arrow-prev {
      color: #333;
  }
  .et_pb_bg_layout_dark_phone .et-pb-arrow-next,
  .et_pb_bg_layout_dark_phone .et-pb-arrow-prev {
      color: #fff;
  }
}
.et_mobile_device .et-pb-arrow-prev {
  left: 22px;
  opacity: 1;
}
.et_mobile_device .et-pb-arrow-next {
  right: 22px;
  opacity: 1;
}
@media (max-width: 767px) {
  .et_mobile_device .et-pb-arrow-prev {
      left: 0;
      opacity: 1;
  }
  .et_mobile_device .et-pb-arrow-next {
      right: 0;
      opacity: 1;
  }
}
.et_pb_button[data-icon]:not([data-icon=""]):after {
  content: attr(data-icon);
}
@media (max-width: 980px) {
  .et_pb_button[data-icon-tablet]:not([data-icon-tablet=""]):after {
      content: attr(data-icon-tablet);
  }
}
@media (max-width: 767px) {
  .et_pb_button[data-icon-phone]:not([data-icon-phone=""]):after {
      content: attr(data-icon-phone);
  }
}
.et_pb_with_border .et_pb_image_wrap {
  border: 0 solid #333;
}
.et_pb_image {
  margin-left: auto;
  margin-right: auto;
  line-height: 0;
}
.et_pb_image.aligncenter {
  text-align: center;
}
.et_pb_image.et_pb_has_overlay a.et_pb_lightbox_image {
  display: block;
  position: relative;
}
.et_pb_image {
  display: block;
}
.et_pb_image .et_pb_image_wrap {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.et_pb_image .et_pb_image_wrap img[src*=".svg"] {
  width: auto;
}
.et_pb_image img {
  position: relative;
}
.et_pb_image_sticky {
  margin-bottom: 0 !important;
  display: inherit;
}
.et_pb_image.et_pb_has_overlay .et_pb_image_wrap:hover .et_overlay {
  z-index: 3;
  opacity: 1;
}
@media (min-width: 981px) {
  .et_pb_section_sticky,
  .et_pb_section_sticky.et_pb_bottom_divider .et_pb_row:nth-last-child(2),
  .et_pb_section_sticky .et_pb_column_single,
  .et_pb_section_sticky .et_pb_row.et-last-child,
  .et_pb_section_sticky .et_pb_row:last-child,
  .et_pb_section_sticky .et_pb_specialty_column .et_pb_row_inner.et-last-child,
  .et_pb_section_sticky .et_pb_specialty_column .et_pb_row_inner:last-child {
      padding-bottom: 0 !important;
  }
}
@media (max-width: 980px) {
  .et_pb_image_sticky_tablet {
      margin-bottom: 0 !important;
      display: inherit;
  }
  .et_pb_section_sticky_mobile,
  .et_pb_section_sticky_mobile.et_pb_bottom_divider .et_pb_row:nth-last-child(2),
  .et_pb_section_sticky_mobile .et_pb_column_single,
  .et_pb_section_sticky_mobile .et_pb_row.et-last-child,
  .et_pb_section_sticky_mobile .et_pb_row:last-child,
  .et_pb_section_sticky_mobile .et_pb_specialty_column .et_pb_row_inner.et-last-child,
  .et_pb_section_sticky_mobile .et_pb_specialty_column .et_pb_row_inner:last-child {
      padding-bottom: 0 !important;
  }
  .et_pb_section_sticky .et_pb_row.et-last-child .et_pb_column.et_pb_row_sticky.et-last-child,
  .et_pb_section_sticky .et_pb_row:last-child .et_pb_column.et_pb_row_sticky:last-child {
      margin-bottom: 0;
  }
  .et_pb_image_bottom_space_tablet {
      margin-bottom: 30px !important;
      display: block;
  }
  .et_always_center_on_mobile {
      text-align: center !important;
      margin-left: auto !important;
      margin-right: auto !important;
  }
}
@media (max-width: 767px) {
  .et_pb_image_sticky_phone {
      margin-bottom: 0 !important;
      display: inherit;
  }
  .et_pb_image_bottom_space_phone {
      margin-bottom: 30px !important;
      display: block;
  }
}
.et_overlay {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.9);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #e5e5e5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}
.et_overlay:before {
  color: #2ea3f2;
  content: "\E050";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 32px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.et_portfolio_image,
.et_shop_image {
  position: relative;
  display: block;
}
.et_pb_has_overlay:not(.et_pb_image):hover .et_overlay,
.et_portfolio_image:hover .et_overlay,
.et_shop_image:hover .et_overlay {
  z-index: 3;
  opacity: 1;
}
#ie7 .et_overlay,
#ie8 .et_overlay {
  display: none;
}
.et_pb_module.et_pb_has_overlay {
  position: relative;
}
.et_pb_module.et_pb_has_overlay .et_overlay,
article.et_pb_has_overlay {
  border: none;
}
.et_pb_text {
  word-wrap: break-word;
}
.et_pb_text ol,
.et_pb_text ul {
  padding-bottom: 1em;
}
.et_pb_text > :last-child {
  padding-bottom: 0;
}
.et_pb_text_inner {
  position: relative;
}
.et_pb_with_border .et-pb-icon,
.et_pb_with_border .et_pb_image_wrap {
  border: 0 solid #333;
}
.et_pb_blurb_content {
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
}
.et_pb_blurb.et_pb_text_align_left .et_pb_blurb_content .et_pb_blurb_container {
  text-align: left;
}
.et_pb_blurb.et_pb_text_align_right .et_pb_blurb_content .et_pb_blurb_container {
  text-align: right;
}
.et_pb_blurb.et_pb_text_align_justified .et_pb_blurb_content .et_pb_blurb_container {
  text-align: justify;
}
.et_pb_blurb.et_pb_text_align_center .et_pb_blurb_content .et_pb_blurb_container {
  text-align: center;
}
.et_pb_blurb_content p:last-of-type {
  padding-bottom: 0;
}
.et_pb_blurb .et_pb_module_header a,
.et_pb_blurb h4 a {
  text-decoration: none;
}
.et_pb_blurb .et_pb_image_wrap {
  display: block;
  margin: auto;
}
.et_pb_main_blurb_image img {
  border-radius: inherit;
}
.et_pb_main_blurb_image img[src*=".svg"] {
  width: auto;
}
.et_pb_sticky_module .et_pb_main_blurb_image .et_pb_image_wrap {
  width: 100%;
  max-width: 100%;
}
.et_pb_blurb_position_left .et_pb_blurb_content,
.et_pb_blurb_position_right .et_pb_blurb_content {
  display: table;
}
.et_pb_blurb_position_left .et_pb_main_blurb_image,
.et_pb_blurb_position_right .et_pb_main_blurb_image {
  width: 32px;
  display: table-cell;
  line-height: 0;
}
.et_pb_blurb_position_left .et_pb_main_blurb_image img,
.et_pb_blurb_position_right .et_pb_main_blurb_image img {
  width: inherit;
}
.et_pb_blurb_position_left .et-pb-icon,
.et_pb_blurb_position_right .et-pb-icon {
  font-size: 32px;
}
.et_pb_blurb_position_right .et-pb-icon {
  display: table-cell;
}
.et_pb_blurb_position_left .et_pb_blurb_container,
.et_pb_blurb_position_right .et_pb_blurb_container {
  display: table-cell;
  vertical-align: top;
}
.et_pb_blurb_position_left .et_pb_blurb_container {
  padding-left: 15px;
}
.et_pb_blurb_position_left .et_pb_blurb_content {
  text-align: left;
}
.et_pb_blurb_position_right .et_pb_blurb_container {
  padding-right: 15px;
}
.et_pb_blurb_position_right .et_pb_blurb_content {
  text-align: right;
}
.et_pb_blurb_position_right .et_pb_main_blurb_image {
  left: auto;
  right: 0;
}
.et_pb_blurb_position_right .et-pb-icon {
  margin-right: 0;
  margin-left: 20px;
}
.et_pb_main_blurb_image {
  display: inline-block;
  margin-bottom: 30px;
  line-height: 0;
  max-width: 100%;
}
@media (max-width: 980px) {
  .et_pb_blurb_position_left_tablet .et_pb_main_blurb_image,
  .et_pb_blurb_position_right_tablet .et_pb_main_blurb_image {
      width: 32px;
      display: table-cell;
      line-height: 0;
  }
  .et_pb_blurb_position_left_tablet .et_pb_main_blurb_image img,
  .et_pb_blurb_position_right_tablet .et_pb_main_blurb_image img {
      width: inherit;
  }
  .et_pb_blurb_position_left_tablet .et-pb-icon,
  .et_pb_blurb_position_right_tablet .et-pb-icon {
      font-size: 32px;
      display: table-cell;
  }
  .et_pb_blurb_position_left_tablet .et_pb_blurb_container,
  .et_pb_blurb_position_right_tablet .et_pb_blurb_container {
      display: table-cell;
      vertical-align: top;
  }
  .et_pb_blurb_position_left_tablet .et_pb_blurb_container {
      padding-left: 15px;
  }
  .et_pb_blurb_position_left_tablet .et_pb_blurb_content {
      text-align: left;
  }
  .et_pb_blurb_position_right_tablet .et_pb_blurb_container {
      padding-right: 15px;
  }
  .et_pb_blurb_position_right_tablet .et_pb_blurb_content {
      padding-right: 52px;
      text-align: right;
  }
  .et_pb_blurb_position_right_tablet .et_pb_main_blurb_image {
      left: auto;
      right: 0;
  }
  .et_pb_blurb_position_right_tablet .et-pb-icon {
      margin-right: 0;
      margin-left: 20px;
  }
  .et_pb_blurb_position_top_tablet .et_pb_main_blurb_image {
      display: block;
      width: auto;
  }
  .et_pb_blurb_position_top_tablet .et-pb-icon {
      font-size: 96px;
      display: initial;
  }
  .et_pb_blurb_position_top_tablet .et_pb_blurb_container {
      display: block;
      padding-left: 0;
      padding-right: 0;
  }
  .et_pb_blurb_position_top_tablet .et_pb_blurb_content {
      display: inline-block;
      text-align: center;
      padding-right: 0;
  }
}
@media (max-width: 767px) {
  .et_pb_blurb_position_left_phone .et_pb_main_blurb_image,
  .et_pb_blurb_position_right_phone .et_pb_main_blurb_image {
      width: 32px;
      display: table-cell;
      line-height: 0;
  }
  .et_pb_blurb_position_left_phone .et_pb_main_blurb_image img,
  .et_pb_blurb_position_right_phone .et_pb_main_blurb_image img {
      width: inherit;
  }
  .et_pb_blurb_position_left_phone .et-pb-icon,
  .et_pb_blurb_position_right_phone .et-pb-icon {
      font-size: 32px;
      display: table-cell;
  }
  .et_pb_blurb_position_left_phone .et_pb_blurb_container,
  .et_pb_blurb_position_right_phone .et_pb_blurb_container {
      display: table-cell;
      vertical-align: top;
  }
  .et_pb_blurb_position_left_phone .et_pb_blurb_container {
      padding-left: 15px;
  }
  .et_pb_blurb_position_left_phone .et_pb_blurb_content {
      text-align: left;
  }
  .et_pb_blurb_position_right_phone .et_pb_blurb_container {
      padding-right: 15px;
  }
  .et_pb_blurb_position_right_phone .et_pb_blurb_content {
      padding-right: 52px;
      text-align: right;
  }
  .et_pb_blurb_position_right_phone .et_pb_main_blurb_image {
      left: auto;
      right: 0;
  }
  .et_pb_blurb_position_right_phone .et-pb-icon {
      margin-right: 0;
      margin-left: 20px;
  }
  .et_pb_blurb_position_top_phone .et_pb_main_blurb_image {
      display: block;
      width: auto;
  }
  .et_pb_blurb_position_top_phone .et-pb-icon {
      font-size: 96px;
      display: initial;
  }
  .et_pb_blurb_position_top_phone .et_pb_blurb_container {
      display: block;
      padding-left: 0;
      padding-right: 0;
  }
  .et_pb_blurb_position_top_phone .et_pb_blurb_content {
      display: inline-block;
      text-align: center;
      padding-right: 0;
  }
}
@media (max-width: 980px) {
  .et_pb_blurb.et_pb_text_align_left-tablet .et_pb_blurb_content .et_pb_blurb_container {
      text-align: left;
  }
  .et_pb_blurb.et_pb_text_align_right-tablet .et_pb_blurb_content .et_pb_blurb_container {
      text-align: right;
  }
  .et_pb_blurb.et_pb_text_align_justified-tablet .et_pb_blurb_content .et_pb_blurb_container {
      text-align: justify;
  }
  .et_pb_blurb.et_pb_text_align_center-tablet .et_pb_blurb_content .et_pb_blurb_container {
      text-align: center;
  }
}
@media (max-width: 767px) {
  .et_pb_blurb.et_pb_text_align_left-phone .et_pb_blurb_content .et_pb_blurb_container {
      text-align: left;
  }
  .et_pb_blurb.et_pb_text_align_right-phone .et_pb_blurb_content .et_pb_blurb_container {
      text-align: right;
  }
  .et_pb_blurb.et_pb_text_align_justified-phone .et_pb_blurb_content .et_pb_blurb_container {
      text-align: justify;
  }
  .et_pb_blurb.et_pb_text_align_center-phone .et_pb_blurb_content .et_pb_blurb_container {
      text-align: center;
  }
}
.et-waypoint.et_pb_animation_off,
.et-waypoint:not(.et_pb_counters).et_pb_animation_off,
.et_pb_animation_off {
  opacity: 1;
}
.et-waypoint.et_pb_animation_left.et-animated,
.et_pb_animation_left.et-animated {
  opacity: 1;
  -webkit-animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
.et-waypoint.et_pb_animation_right.et-animated,
.et_pb_animation_right.et-animated {
  opacity: 1;
  -webkit-animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
.et-waypoint.et_pb_animation_top.et-animated,
.et_pb_animation_top.et-animated {
  opacity: 1;
  -webkit-animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
.et-waypoint.et_pb_animation_bottom.et-animated,
.et_pb_animation_bottom.et-animated {
  opacity: 1;
  -webkit-animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
.et-waypoint.et_pb_animation_fade_in.et-animated,
.et_pb_animation_fade_in.et-animated {
  opacity: 1;
  -webkit-animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
@media (max-width: 980px) {
  .et-waypoint.et_pb_animation_off_tablet,
  .et-waypoint:not(.et_pb_counters).et_pb_animation_off_tablet,
  .et_pb_animation_off_tablet {
      opacity: 1;
      -webkit-animation: none;
      animation: none;
  }
  .et-waypoint.et_pb_animation_left_tablet.et-animated,
  .et_pb_animation_left_tablet.et-animated {
      opacity: 1;
      -webkit-animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_right_tablet.et-animated,
  .et_pb_animation_right_tablet.et-animated {
      opacity: 1;
      -webkit-animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_top_tablet.et-animated,
  .et_pb_animation_top_tablet.et-animated {
      opacity: 1;
      -webkit-animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_bottom_tablet.et-animated,
  .et_pb_animation_bottom_tablet.et-animated {
      opacity: 1;
      -webkit-animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_fade_in_tablet.et-animated,
  .et_pb_animation_fade_in_tablet.et-animated {
      opacity: 1;
      -webkit-animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
}
@media (max-width: 767px) {
  .et-waypoint.et_pb_animation_off_phone,
  .et-waypoint:not(.et_pb_counters).et_pb_animation_off_phone,
  .et_pb_animation_off_phone {
      opacity: 1;
      -webkit-animation: none;
      animation: none;
  }
  .et-waypoint.et_pb_animation_left_phone.et-animated,
  .et_pb_animation_left_phone.et-animated {
      opacity: 1;
      -webkit-animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeLeft 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_right_phone.et-animated,
  .et_pb_animation_right_phone.et-animated {
      opacity: 1;
      -webkit-animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_top_phone.et-animated,
  .et_pb_animation_top_phone.et-animated {
      opacity: 1;
      -webkit-animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_bottom_phone.et-animated,
  .et_pb_animation_bottom_phone.et-animated {
      opacity: 1;
      -webkit-animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeBottom 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
  .et-waypoint.et_pb_animation_fade_in_phone.et-animated,
  .et_pb_animation_fade_in_phone.et-animated {
      opacity: 1;
      -webkit-animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
      animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
}

.et_pb_section_0_tb_header.et_pb_section {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #12111d!important
}

.et_pb_row_0_tb_header,body #page-container .et-db #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row,.et_pb_row_1_tb_header,body #page-container .et-db #et-boc .et-l .et_pb_row_1_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_1_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_1_tb_header.et_pb_row,.et_pb_row_2_tb_header,body #page-container .et-db #et-boc .et-l .et_pb_row_2_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_2_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_2_tb_header.et_pb_row {
  width: 100%;
  max-width: 1200px
}

.et_pb_section_1_tb_header.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #212335!important
}

.et_pb_row_1_tb_header.et_pb_row,.et_pb_row_2_tb_header.et_pb_row,.et_pb_row_3_tb_header.et_pb_row,.et_pb_row_4_tb_header.et_pb_row,.et_pb_row_5_tb_header.et_pb_row {
  padding-top: 0px!important;
  padding-right: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 0px!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px
}

.et_pb_section_2_tb_header.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #2a3254!important
}

.et_pb_section_3_tb_header.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #191e32!important
}

.et_pb_row_3_tb_header,body #page-container .et-db #et-boc .et-l .et_pb_row_3_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_3_tb_header.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_3_tb_header.et_pb_row {
  width: 100%
}

.et_pb_section_4_tb_header.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px
}

.et_pb_section_5_tb_header.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #24224e!important
}

.et_pb_row_0_tb_header.et_pb_row {
  padding-top: 0px!important;
  padding-right: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 0px!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  margin-left: auto!important;
  margin-right: auto!important;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px
}

@media only screen and (min-width: 981px) {
  .et_pb_section_3_tb_header,.et_pb_section_4_tb_header,.et_pb_section_5_tb_header {
      display:none!important
  }
}

@media only screen and (min-width: 768px) and (max-width:980px) {
  .et_pb_section_0_tb_header,.et_pb_section_1_tb_header,.et_pb_section_2_tb_header {
      display:none!important
  }
}

@media only screen and (max-width: 767px) {
  .et_pb_section_0_tb_header,.et_pb_section_1_tb_header,.et_pb_section_2_tb_header {
      display:none!important
  }
}

div.et_pb_section.et_pb_section_8_tb_body {
  background-position: center top;
  background-image: url(https://bd.mcwaffiliates.com/wp-content/uploads/2023/04/bg_contactUs-scaled.jpg)!important
}

.et_pb_section_8_tb_body.et_pb_section {
  padding-top: 60px;
  padding-right: 15px;
  padding-bottom: 60px;
  padding-left: 15px;
  margin-top: 0px;
  margin-bottom: 0px
}

.et_pb_row_7_tb_body,body #page-container .et-db #et-boc .et-l .et_pb_row_7_tb_body.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_7_tb_body.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_7_tb_body.et_pb_row {
  width: 100%
}

.et_pb_text_3_tb_body {
  font-size: 24px
}

.et_pb_text_3_tb_body h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 38px;
  color: #FFFFFF!important;
  text-align: center
}

@media only screen and (max-width: 980px) {
  .et_pb_section_8_tb_body.et_pb_section {
      padding-top:60px;
      padding-right: 15px;
      padding-bottom: 60px;
      padding-left: 15px
  }

  .et_pb_text_3_tb_body h1 {
      font-size: 38px
  }
}

@media only screen and (max-width: 767px) {
  div.et_pb_section.et_pb_section_8_tb_body {
      background-image:url(https://bd.mcwaffiliates.com/wp-content/uploads/2023/04/bg_mob_contactUs-2.jpg)!important
  }

  .et_pb_section_8_tb_body.et_pb_section {
      padding-top: 20px;
      padding-right: 15px;
      padding-bottom: 20px;
      padding-left: 15px
  }

  .et_pb_text_3_tb_body h1 {
      font-size: 28px
  }
}

.et_pb_section_0_tb_footer.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #12111d!important
}

.et_pb_row_0_tb_footer.et_pb_row {
  padding-top: 0px!important;
  padding-right: 0px!important;
  padding-bottom: 0px!important;
  padding-left: 0px!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px
}

.et_pb_row_0_tb_footer,body #page-container .et-db #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row,.et_pb_row_1_tb_footer,body #page-container .et-db #et-boc .et-l .et_pb_row_1_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_1_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_1_tb_footer.et_pb_row {
  width: 100%;
  max-width: 1200px
}

.et_pb_section_1_tb_footer.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #191a25!important
}

.et_pb_code_1_tb_footer {
  background-color: RGBA(255,255,255,0);
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px!important;
  margin-bottom: 0px!important
}

.et_pb_section_2_tb_footer {
  position: absolute!important;
  bottom: 0px;
  top: auto;
  left: 0px;
  right: auto
}

.et_pb_section_2_tb_footer.et_pb_section {
  background-color: RGBA(255,255,255,0)!important
}

@media only screen and (min-width: 981px) {
  .et_pb_section_1_tb_footer,.et_pb_code_1_tb_footer {
      display:none!important
  }
}

@media only screen and (min-width: 768px) and (max-width:980px) {
  .et_pb_section_0_tb_footer {
      display:none!important
  }
}

@media only screen and (max-width: 767px) {
  .et_pb_section_0_tb_footer {
      display:none!important
  }

  .et_pb_row_2_tb_footer,body #page-container .et-db #et-boc .et-l .et_pb_row_2_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_2_tb_footer.et_pb_row,body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_2_tb_footer.et_pb_row {
      width: 60%
  }
}
button{
  border: none;
}
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.splash-screen img {
  max-width: 100%;
  max-height: 100%;
}
.text-right{
  text-align: end;
}
/* src/MaintenancePage.css */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.message-box {
  text-align: center;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

h1 {
  font-size: 32px;
  color: #333;
}

p {
  font-size: 18px;
  color: #555;
}

.timer {
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
  color: #ff5722;
}
.skiptranslate{
  display: none !important;
}
.VIpgJd-suEOdc{
  display: none !important;
}
#goog-gt-tt{
  display: none !important;
}
.intercom-lightweight-app-launcher{
  display: none !important;
}
.intercom-namespace .intercom-with-namespace-ymm01w{
  display: none !important;
}
.intercom-namespace .intercom-with-namespace-1sapft2 {
  display: none !important;
}
.intercom-1bipqnp{
  display: none !important;
}
.make-payment{
  background: #21f805;
  height: 100vh;
  overflow: auto;
  width: 100%;
  position: relative;
  padding: 5px;
}
.make-payment.bkash{
  background: #E6007E;
  
}
.make-payment.rocket{
  background: #8A2893;
}
.make-payment.nagad{
  background: #f07008;
}
.make-payment.upay{
  background: #0846f0;
}
.payment-method-icon{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.payment-method-icon .payment-icon{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: #ffffff solid 5px;
}
.payment-account span{
  display: block;
  padding: 0px 10px;
  line-height: 21px;
  
}

.payment-form{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.payment-form input{
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
}
.btn.pay{
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  border: solid 3px #fff;
  background: #21f805;
  border-radius: 5px;
  color: #fff;
}
.btn.pay.bkash{
  
  background: #E6007E;
 
}
.btn.pay.rocket{
  background: #8A2893;
}
.btn.pay.nagad{
  background: #f07008;
}
.btn.pay.upay{
  background: #0846f0;
}
.open-game-mask{
  position:fixed;
  z-index:10001;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.get-vcode-btn a{
  border: none;
  border-radius: 10px;
  background: green;
  color: white;
  padding: 3px;
  font-size: 11px;
}
body{
  top: 0 !important;
}
